import React from 'react';

const TokenInput = ({
    label,
    token,
    balance,
    tokenName,
    onChange,
    onMax,
    placeholder = '0.0',
}) => {
    return (
        <div className='token-amount-container'>
            <div className='token-balance'>
                <h3>{label}</h3>
                <div className='balance'>
                    Balance: <span>{balance}</span>
                </div>
            </div>
            <div className='input-container'>
                <input
                    type='number'
                    name={tokenName}
                    value={token}
                    onChange={onChange}
                    placeholder={placeholder}
                />
                <button type='button' onClick={onMax}>
                    max
                </button>
            </div>
        </div>
    );
};

export default TokenInput;
