import { useWeb3React } from '@web3-react/core';
import React, { Fragment, useContext, useEffect } from 'react';
import SwapIcon from '../../assets/icons/SwapIcon';
import config from '../../config/config';
import walletContext from '../../context/wallet/walletContext';

const EthTab = () => {
    const { selectedSymbol, price, getPrice } = useContext(walletContext);

    const { chainId } = useWeb3React();

    // in case chain id is undefined, use app symbol
    const token = config.tokensByNetwork[chainId]?.[1]?.symbol ?? config.appSymbol;

    useEffect(() => {
        if (!chainId) {
            return;
        }

        getPrice();

        // eslint-disable-next-line
    }, [chainId]);

    return (
        <Fragment>
            <div className='token__1'>
                <h3>{selectedSymbol}</h3>
                <p>
                    {selectedSymbol} per {token}
                </p>
                <span>{price?.[0] ? parseFloat(price?.[0]).toPrecision(4) : '0.00'}</span>
            </div>
            <SwapIcon />
            <div className='token__2'>
                <h3>{token}</h3>
                <p>
                    {token} per {selectedSymbol}
                </p>
                <span>{price?.[1] ? parseFloat(price?.[1]).toPrecision(4) : '0.00'}</span>
            </div>
        </Fragment>
    );
};

export default EthTab;
