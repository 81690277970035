const RedMeteor = () => (
    <svg
        width='91'
        height='91'
        viewBox='0 0 91 91'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        id='red-meteor'
    >
        <path
            d='M7.74603 57.9147C6.54567 46.4203 11.1541 33.1195 18.9079 24.5677C22.3729 20.7554 26.4487 17.6118 30.8685 15.3862C43.8193 8.85672 63.3328 5.47671 74.3802 15.3101C77.3084 17.9115 79.1814 21.6388 80.9997 25.2863C84.0142 31.3395 83.9087 37.6843 81.9332 44.5485C80.112 50.8691 77.8514 56.0569 73.7979 60.9929C69.8652 65.7805 67.1257 71.6052 62.6157 75.8576C56.2225 81.8852 47.391 83.7202 39.4028 82.7613C32.5012 81.9341 25.4952 80.3696 19.4033 76.9673C12.1689 72.917 8.57197 65.8492 7.74603 57.9147Z'
            fill='url(#redMeteorPaint0_radial)'
        />
        <path
            d='M55.0129 42.2493C57.1545 39.3006 58.2101 36.2375 56.3481 33.8257C54.0844 30.893 50.5973 28.1759 47.2374 26.8757C43.1493 25.2992 38.4704 25.9421 34.1088 27.2296C31.7851 27.9163 29.4462 28.8097 27.4886 30.4213C21.7043 35.1756 22.8443 44.3518 26.5663 49.3598C29.2297 52.9392 33.7703 55.6624 38.2692 54.9278C41.6749 54.3714 43.9782 51.8803 46.7595 49.8184C49.7371 47.608 52.7512 45.3724 55.0129 42.2493Z'
            fill='#AA044A'
        />
        <path
            d='M65.2047 51.1248C66.9244 50.7173 68.956 50.6405 70.0066 52.0602C70.7767 53.1146 70.7505 54.6799 70.376 56.0566C69.6151 58.8574 67.8963 60.0508 66.0726 62.0058C63.7252 64.5175 61.3847 67.0309 59.0352 69.5505C58.138 70.5146 57.2337 71.4771 56.1983 72.2507C55.1194 73.0478 53.7912 73.6204 52.6033 73.2641C51.2994 72.8644 50.5102 71.3877 50.3689 69.8926C49.8602 64.6272 52.4428 59.5868 56.1276 56.0281C58.7203 53.5225 61.9388 51.9045 65.2047 51.1248Z'
            fill='#AA044A'
        />
        <path
            d='M75.9096 29.9016C74.755 28.0091 72.937 26.6525 70.8734 26.1373C69.4804 25.7928 67.4483 25.9477 66.7746 27.7567C66.4439 28.6373 66.6559 29.6005 66.9086 30.4639C67.8227 33.6186 69.6803 39.8424 72.6641 41.3153C73.9658 41.9578 75.6479 41.4373 76.8055 40.3518C78.9847 38.3008 77.1494 31.9311 75.9096 29.9016Z'
            fill='#AA044A'
        />
        <path
            d='M27.8847 73.5796C27.7425 73.0971 27.323 72.6611 26.9322 72.3506C23.8178 69.8775 20.6983 67.3947 17.584 64.9216C16.8975 64.3733 16.211 63.825 15.5224 63.2847C15.2636 63.0809 14.971 62.8608 14.6384 62.8862C14.1146 62.9185 13.7166 63.5568 13.7064 64.1316C13.6962 64.7064 13.9673 65.2186 14.2415 65.689C16.8903 70.1938 22.3578 75.2111 27.8284 74.3645C27.9553 74.1051 27.9563 73.8337 27.8847 73.5796Z'
            fill='#AA044A'
        />
        <path
            d='M49.8153 13.6122C50.0915 13.7181 50.4024 13.9253 50.3707 14.2574C50.3493 14.4899 50.16 14.667 49.9739 14.8023C49.2292 15.3437 48.3598 15.6019 47.5108 15.8393C45.7454 16.341 43.9114 16.844 42.1878 16.4392C41.973 16.3898 41.7449 16.3035 41.656 16.0879C41.5066 15.7227 41.8595 15.3203 42.1889 15.0991C44.3195 13.6125 47.5118 12.7178 49.8153 13.6122Z'
            fill='#AA044A'
        />
        <defs>
            <radialGradient
                id='redMeteorPaint0_radial'
                cx='0'
                cy='0'
                r='1'
                gradientUnits='userSpaceOnUse'
                gradientTransform='translate(32.9959 73.7415) rotate(165.854) scale(70.3132 73.949)'
            >
                <stop stopColor='#FFA23F' />
                <stop offset='0.1088' stopColor='#F99240' />
                <stop offset='0.3221' stopColor='#E86841' />
                <stop offset='0.6164' stopColor='#CE2444' />
                <stop offset='0.7262' stopColor='#C30945' />
                <stop offset='0.7735' stopColor='#C60F43' />
                <stop offset='0.8358' stopColor='#D01F3C' />
                <stop offset='0.9065' stopColor='#E13B30' />
                <stop offset='0.9829' stopColor='#F76020' />
                <stop offset='1' stopColor='#FD6A1C' />
            </radialGradient>
        </defs>
    </svg>
);

export default RedMeteor;
