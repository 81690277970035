import React from 'react';

const Loading = () => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='200px'
            height='200px'
            viewBox='0 0 100 100'
            preserveAspectRatio='xMidYMid'
        >
            <circle
                cx='50'
                cy='50'
                fill='none'
                stroke='#ff763e'
                strokeWidth='10'
                r='35'
                strokeDasharray='164.93361431346415 56.97787143782138'
            ></circle>
        </svg>
    );
};

export default Loading;
