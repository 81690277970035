import React from 'react';

const MetaMask = () => {
    return (
        <svg
            id='metamask'
            width='38'
            height='38'
            viewBox='0 0 38 38'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M36.0023 1.49408L21.2659 12.439L23.991 5.98165L36.0023 1.49408Z'
                fill='white'
                fillOpacity='0.6'
            />
            <path
                d='M1.98279 1.49408L16.6007 12.5427L14.0088 5.98165L1.98279 1.49408Z'
                fill='white'
                fillOpacity='0.9'
            />
            <path
                d='M30.7002 26.8644L26.7755 32.8774L35.1731 35.1878L37.5871 26.9976L30.7002 26.8644Z'
                fill='white'
                fillOpacity='0.9'
            />
            <path
                d='M0.427673 26.9976L2.82696 35.1878L11.2245 32.8774L7.29971 26.8644L0.427673 26.9976Z'
                fill='white'
                fillOpacity='0.9'
            />
            <path
                d='M10.7505 16.7044L8.41046 20.2441L16.7488 20.6144L16.4525 11.654L10.7505 16.7044Z'
                fill='white'
                fillOpacity='0.9'
            />
            <path
                d='M27.2345 16.7044L21.4585 11.5504L21.2659 20.6144L29.5893 20.2441L27.2345 16.7044Z'
                fill='white'
                fillOpacity='0.9'
            />
            <path
                d='M11.2245 32.8773L16.2304 30.4337L11.9058 27.0569L11.2245 32.8773Z'
                fill='white'
                fillOpacity='0.9'
            />
            <path
                d='M21.7548 30.4337L26.7754 32.8773L26.0794 27.0569L21.7548 30.4337Z'
                fill='white'
                fillOpacity='0.9'
            />
            <path
                d='M26.7754 32.8774L21.7548 30.4337L22.1546 33.7068L22.1102 35.0841L26.7754 32.8774Z'
                fill='white'
            />
            <path
                d='M11.2245 32.8774L15.8898 35.0841L15.8602 33.7068L16.2304 30.4337L11.2245 32.8774Z'
                fill='white'
            />
            <path
                d='M11.2245 32.8774L11.9354 26.8644L7.29974 26.9976L11.2245 32.8774Z'
                fill='white'
                fillOpacity='0.8'
            />
            <path
                d='M26.0646 26.8644L26.7754 32.8774L30.7002 26.9976L26.0646 26.8644Z'
                fill='white'
                fillOpacity='0.8'
            />
            <path
                d='M29.5893 20.244L21.2659 20.6144L22.0361 24.8946L23.2654 22.3175L26.2273 23.6653L29.5893 20.244Z'
                fill='white'
                fillOpacity='0.8'
            />
            <path
                d='M11.7863 23.6653L14.7485 22.3175L15.9628 24.8946L16.7478 20.6144L8.40955 20.244L11.7863 23.6653Z'
                fill='white'
                fillOpacity='0.8'
            />
            <path
                d='M8.41046 20.244L11.9057 27.0569L11.7872 23.6653L8.41046 20.244Z'
                fill='white'
            />
            <path
                d='M26.2275 23.6653L26.0794 27.0569L29.5895 20.244L26.2275 23.6653Z'
                fill='white'
            />
            <path
                d='M16.7488 20.6144L15.9638 24.8946L16.9413 29.9449L17.1635 23.295L16.7488 20.6144Z'
                fill='white'
            />
            <path
                d='M21.2659 20.6144L20.866 23.2802L21.0437 29.9449L22.036 24.8946L21.2659 20.6144Z'
                fill='white'
            />
            <path
                d='M22.0361 24.8946L21.0438 29.9449L21.7547 30.4337L26.0794 27.0569L26.2275 23.6653L22.0361 24.8946Z'
                fill='white'
            />
            <path
                d='M11.7873 23.6653L11.9058 27.0569L16.2305 30.4337L16.9413 29.9449L15.9638 24.8946L11.7873 23.6653Z'
                fill='white'
            />
            <path
                d='M22.1102 35.0841L22.1546 33.7068L21.7844 33.381H16.2008L15.8602 33.7068L15.8898 35.0841L11.2245 32.8773L12.8536 34.2103L16.1563 36.506H21.8287L25.1462 34.2103L26.7754 32.8773L22.1102 35.0841Z'
                fill='white'
                fillOpacity='0.7'
            />
            <path
                d='M36.6244 13.1499L37.8834 7.10724L36.0024 1.49408L21.7548 12.0687L27.2346 16.7044L34.9805 18.9704L36.6985 16.9709L35.958 16.4378L37.1429 15.3567L36.2245 14.6457L37.4094 13.7423L36.6244 13.1499Z'
                fill='white'
                fillOpacity='0.3'
            />
            <path
                d='M0.116699 7.10724L1.37559 13.1499L0.575823 13.7423L1.76065 14.6457L0.85722 15.3567L2.04206 16.4378L1.30154 16.9709L3.00473 18.9704L10.7506 16.7044L16.2305 12.0687L1.98281 1.49408L0.116699 7.10724Z'
                fill='white'
                fillOpacity='0.3'
            />
            <path
                d='M34.9805 18.9704L27.2346 16.7044L29.5895 20.244L26.0794 27.0569L30.7002 26.9976H37.5871L34.9805 18.9704Z'
                fill='white'
            />
            <path
                d='M10.7505 16.7044L3.00469 18.9704L0.427673 26.9976H7.29971L11.9058 27.0569L8.4105 20.244L10.7505 16.7044Z'
                fill='white'
            />
            <path
                d='M21.2659 20.6144L21.7547 12.0687L24.0058 5.98163H14.0088L16.2304 12.0687L16.7487 20.6144L16.9264 23.3098L16.9412 29.9449H21.0437L21.0734 23.3098L21.2659 20.6144Z'
                fill='white'
            />
        </svg>
    );
};

export default MetaMask;
