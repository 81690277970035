import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import {
    ETH,
    // USDC
} from '../../config/constants';
import { useWeb3React } from '@web3-react/core';
import EthIcon from '../../assets/icons/EthIcon';
// import UsdcIcon from '../../assets/icons/UsdcIcon';
import config from '../../config/config';
import walletContext from '../../context/wallet/walletContext';
import LPTokens from '../../liquidity/LPTokens';
import Tab from '../reusable/Tab';
import EthTab from '../home/EthTab';
import UsdcTab from '../home/UsdcTab';
import Apy from '../home/Apy';
import Rewards from '../../rewards/Rewards';
import useSWR from 'swr';
import { WETH } from '@uniswap/sdk';
import { fetcher } from '../../web3/utils';
import { formatUnits } from '@ethersproject/units';
import GradientBorderButton from '../reusable/GradientBorderButton';

const Home = () => {
    const { selectedSymbol, setSelectedSymbol } = useContext(walletContext);

    const { chainId, library, account } = useWeb3React();

    const { data: pairAddress } = useSWR(
        [
            config.uniswap.addresses.factory,
            'getPair',

            selectedSymbol === ETH
                ? WETH[chainId]?.address
                : config.tokensByNetwork[chainId]?.[0].address ?? '',
            config.tokensByNetwork[chainId]?.[1].address ?? '',
        ],
        {
            fetcher: fetcher(library, config.uniswap.abis.factory),
        }
    );

    const { data: lpBalance } = useSWR([pairAddress ?? '', 'balanceOf', account], {
        fetcher: fetcher(library, config.erc20abi),
    });

    return (
        <section id='home'>
            <div className='container'>
                <div className='swap-container'>
                    <div className='tabs-container'>
                        {/* <Tab
                            label={
                                chainId ? config.tokensByNetwork[chainId][0].symbol : USDC
                            }
                            icon={<UsdcIcon />}
                            active={selectedSymbol === USDC}
                            onClick={() => setSelectedSymbol(USDC)}
                        /> */}
                        <Tab
                            label={ETH}
                            icon={<EthIcon />}
                            active={selectedSymbol === ETH}
                            onClick={() => setSelectedSymbol(ETH)}
                        />
                    </div>
                    <div className='tabs-content'>
                        <div className='inner__left'>
                            {selectedSymbol === ETH ? <EthTab /> : <UsdcTab />}
                        </div>
                        <div className='inner__right'>
                            <LPTokens />
                            <Apy />
                        </div>
                    </div>
                    <Rewards />
                    <div
                        className={`links-container ${
                            lpBalance && formatUnits(lpBalance, 18) > 0 ? '' : 'add-only'
                        }`}
                    >
                        <GradientBorderButton
                            buttonComponent={<Link to='/liquidity/remove' />}
                            buttonText='Remove Liquidity'
                        />
                        <Link to='/liquidity/add' className='liquidity'>
                            Add Liquidity
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Home;
