import { InjectedConnector } from '@web3-react/injected-connector';
import { NetworkConnector } from '@web3-react/network-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { LedgerConnector } from '@web3-react/ledger-connector';
import { networks } from '../config/constants';

const rpcUrls = {
    [networks.MainNet]: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
    [networks.Ropsten]: `https://ropsten.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
    [networks.Rinkeby]: `https://rinkeby.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
    [networks.Goerli]: `https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
    [networks.Kovan]: `https://kovan.infura.iov3/${process.env.REACT_APP_INFURA_ID}`,
};

const pollingInterval = 12000;

export const injectedConnector = new InjectedConnector({
    supportedChainIds: [
        networks.MainNet,
        // networks.Ropsten,
        // networks.Rinkeby,
        // networks.Goerli,
        // networks.Kovan,
    ],
});

export const InfuraConnector = new NetworkConnector({
    urls: {
        [networks.MainNet]: rpcUrls[networks.MainNet],
        // [networks.Ropsten]: rpcUrls[networks.Ropsten],
        // [networks.Rinkeby]: rpcUrls[networks.Rinkeby],
        // [networks.Goerli]: rpcUrls[Goerli],
        // [networks.Kovan]: rpcUrls[networks.Kovan],
    },
    defaultChainId: networks.MainNet,
    pollingInterval,
});

export const WalletConnector = new WalletConnectConnector({
    rpc: {
        [networks.MainNet]: rpcUrls[networks.MainNet],
        // [networks.Ropsten]: rpcUrls[networks.Ropsten],
        // [networks.Rinkeby]: rpcUrls[networks.Rinkeby],
        // [networks.Goerli]: rpcUrls[networks.Goerli],
        // [networks.Kovan]: rpcUrls[networks.Kovan],
    },
    bridge: 'https://bridge.walletconnect.org',
    qrcode: true,
    pollingInterval,
});

export const LedgerConnect = new LedgerConnector({
    chainId: networks.MainNet,
    url: rpcUrls[networks.MainNet],
    pollingInterval,
});
