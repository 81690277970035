import React from 'react';
import ReactModal from 'react-modal';
import CrossIcon from '../../assets/icons/CrossIcon';

const Prompt = (props) => {
    return (
        <ReactModal
            {...props}
            overlayClassName='modal-overlay'
            className='modal-content prompt'
            shouldCloseOnOverlayClick={true}
        >
            <div className='header'>
                <h2>Confirmation</h2>
                <div className='cross-container' onClick={props.onRequestClose}>
                    <CrossIcon />
                </div>
            </div>
            <div className='content'>
                <p>{props.title || 'Title'}</p>
                <div className='buttons-container'>
                    <button
                        type='button'
                        className='cancel'
                        onClick={props.onRequestClose}
                    >
                        Cancel
                    </button>
                    <button
                        type='button'
                        className='confirm'
                        onClick={props.onConfirm}
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </ReactModal>
    );
};

export default Prompt;
