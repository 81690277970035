// import { useWeb3React } from '@web3-react/core';
import React from 'react'; // , { useContext }
// import config from '../config/config';
// import { ETH } from '../config/constants';
// import walletContext from '../context/wallet/walletContext';

const PooledTokens = ({ amount }) => {
    // const { chainId } = useWeb3React();

    // const { selectedSymbol } = useContext(walletContext);

    return (
        <div className='pooled-token-container'>
            <div className='row'>
                <div className='token-amount'>
                    {amount.token1Current
                        ? amount.token1Current.toSignificant(6)
                        : '0.00'}
                </div>
                <div className='token-name'>
                    {/* {selectedSymbol === ETH
                        ? ETH
                        : config.tokensByNetwork[chainId]?.[0]?.symbol} */}
                    IXS
                </div>
            </div>
            <div className='row'>
                <div className='token-amount'>
                    {amount.token2Current
                        ? amount.token2Current.toSignificant(6)
                        : '0.00'}
                </div>
                <div className='token-name'>
                    {/* {config.tokensByNetwork[chainId]?.[1]?.symbol} */}
                    ETH
                </div>
            </div>
        </div>
    );
};

export default PooledTokens;
