import { MaxUint256 } from '@ethersproject/constants';
import { Contract } from '@ethersproject/contracts';
import { formatUnits, parseUnits } from '@ethersproject/units';
import { useWeb3React } from '@web3-react/core';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import CrossIcon from '../../assets/icons/CrossIcon';
import config from '../../config/config';
import { ETH } from '../../config/constants';
import walletContext from '../../context/wallet/walletContext';
import PooledTokens from '../../liquidity/PooledTokens';
import RemoveLiquidityAmount from '../../liquidity/RemoveLiquidityAmount';
import { calculateMargin } from '../../web3/utils';
import Prompt from '../modals/Prompt';

const RemoveLiquidity = () => {
    const { selectedSymbol, getPair, openModal, closeModal, setModalMessage } =
        useContext(walletContext);

    const { chainId, account, library } = useWeb3React();

    const [amount, setAmount] = useState({
        lpInput: '',
        lpBalance: '',
        token1: '',
        token2: '',
        token1Current: '',
        token2Current: '',
    });

    const [insufficientBalance, setInsufficientBalance] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [approved, setApproved] = useState(false);
    const [allowance, setAllowance] = useState('');
    const [modalOpen, setModalOpen] = useState({
        approve: false,
        withdraw: false,
    });

    const liquidityButtons = [
        {
            label: isLoading && !approved ? 'Approving...' : 'Approve',
            onClick: () => {
                setModalOpen((prev) => ({ ...prev, approve: true }));
            },
            disabled: !amount.lpInput || approved,
        },

        {
            label: isLoading && approved ? 'Withdrawing...' : 'Withdraw',

            onClick: () => {
                setModalOpen((prev) => ({ ...prev, withdraw: true }));
            },
            disabled: !approved || !amount.lpInput,
        },
    ];

    const resetInputAmount = () => {
        setAmount((prev) => ({ ...prev, lpInput: '' }));
    };

    const closeRemoveLiquidityModal = (modal) => {
        setModalOpen((prev) => ({ ...prev, [modal]: false }));
    };

    const approve = async () => {
        closeRemoveLiquidityModal('approve');
        try {
            setIsLoading(true);
            const pair = await getPair();

            const contract = new Contract(
                pair.liquidityToken.address,
                config.uniswap.abis.pair,
                library.getSigner()
            );

            const spender = config.uniswap.addresses.router;

            let useExact = false;

            const estimatedGas = await contract.estimateGas
                .approve(spender, MaxUint256)
                .catch(() => {
                    useExact = true;
                    return contract.estimateGas.approve(spender, amount.lpInput);
                });

            const approve = await contract.approve(
                spender,
                useExact ? amount.lpInput : MaxUint256,
                {
                    gasLimit: calculateMargin(estimatedGas),
                }
            );

            await approve.wait();

            setApproved(true);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const withdrawLiquidity = async () => {
        closeRemoveLiquidityModal('withdraw');
        openModal('confirmation');
        const contract = new Contract(
            config.uniswap.addresses.router,
            config.uniswap.abis.router,
            library.getSigner()
        );
        try {
            setIsLoading(true);

            const token1Input = amount.token1Current.toExact();
            const token2Input = amount.token2Current.toExact();

            if (selectedSymbol === ETH) {
                const amountTokenMin = token1Input - token1Input * config.slippage;

                const amountETHMin = token2Input - token2Input * config.slippage;

                const withdrawETH = await contract.removeLiquidityETH(
                    config.tokensByNetwork[chainId][1].address,
                    parseUnits(amount.lpInput),
                    parseUnits(amountTokenMin.toFixed(10).toString()),
                    parseUnits(amountETHMin.toFixed(10).toString()),
                    account,
                    Date.now() + 15 * 60000
                );

                setModalMessage(withdrawETH.hash);
                openModal('transactionSuccess');
                closeModal('confirmation');

                await withdrawETH.wait();

                resetInputAmount();
                return;
            }

            const amountAMin = token1Input - token1Input * config.slippage;
            const amountBMin = token2Input - token2Input * config.slippage;

            const withdraw = await contract.removeLiquidity(
                config.tokensByNetwork[chainId][0].address,
                config.tokensByNetwork[chainId][1].address,
                parseUnits(amount.lpInput),
                parseUnits(
                    Number(amountAMin)
                        .toFixed(config.tokensByNetwork[chainId][0].decimals)
                        .toString(),
                    config.tokensByNetwork[chainId][0].decimals
                ),
                parseUnits(
                    Number(amountBMin).toString(),
                    config.tokensByNetwork[chainId][1].decimals
                ),
                account,
                Date.now() + 15 * 60000
            );

            setModalMessage(withdraw.hash);
            openModal('transactionSuccess');
            closeModal('confirmation');

            await withdraw.wait();

            resetInputAmount();
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!library || !account) return;

        (async () => {
            const pair = await getPair();

            const contract = new Contract(
                pair.liquidityToken.address,
                config.erc20abi,
                library.getSigner()
            );

            const allowance = await contract.allowance(
                account,
                config.uniswap.addresses.router
            );

            const formattedAllowance = formatUnits(allowance.toString(), 18);

            setAllowance(formattedAllowance);
        })();
        // eslint-disable-next-line
    }, [library, account]);

    useEffect(() => {
        if (amount.lp && parseFloat(amount.lpInput) > allowance) {
            setApproved(false);
            return;
        }

        if (allowance > 0) {
            setApproved(true);
        }
    }, [amount, allowance]);

    return (
        <section id='remove-liquidity'>
            <div className='container'>
                <div className='liquidity-form'>
                    <div className='header'>
                        <h2>Remove Liquidity</h2>
                        <Link to='/'>
                            <CrossIcon />
                        </Link>
                    </div>
                    <div className='content'>
                        <RemoveLiquidityAmount
                            amount={amount}
                            setAmount={setAmount}
                            setInsufficientBalance={setInsufficientBalance}
                        />
                        <PooledTokens amount={amount} />
                        <div className='liquidity-buttons-container'>
                            {account ? (
                                amount.lpInput ? (
                                    !insufficientBalance ? (
                                        liquidityButtons.map(
                                            ({ label, onClick, disabled }, index) => (
                                                <div
                                                    key={label}
                                                    className='liquidity-button-row'
                                                >
                                                    <div className='step'>
                                                        {index + 1}
                                                    </div>
                                                    <button
                                                        type='button'
                                                        onClick={onClick}
                                                        disabled={disabled}
                                                    >
                                                        {label}
                                                    </button>
                                                </div>
                                            )
                                        )
                                    ) : (
                                        <div className='liquidity-button-row single'>
                                            <button disabled>Insufficient Balance</button>
                                        </div>
                                    )
                                ) : (
                                    <div className='liquidity-button-row single'>
                                        <button disabled>Enter an amount</button>
                                    </div>
                                )
                            ) : (
                                <div className='liquidity-button-row single'>
                                    <button disabled>Connect Wallet</button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Prompt
                ariaHideApp={false}
                isOpen={modalOpen.approve}
                title={`By approving, you give ${config.appName} the permission to remove liquidity`}
                onRequestClose={() => closeRemoveLiquidityModal('approve')}
                onConfirm={approve}
            />
            <Prompt
                ariaHideApp={false}
                isOpen={modalOpen.withdraw}
                title='Are you sure you want to withdraw liquidity?'
                onRequestClose={() => closeRemoveLiquidityModal('withdraw')}
                onConfirm={withdrawLiquidity}
            />
        </section>
    );
};

export default RemoveLiquidity;
