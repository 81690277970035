import { Contract } from '@ethersproject/contracts';
import { parseUnits } from '@ethersproject/units';
import { TokenAmount } from '@uniswap/sdk';
import { useWeb3React } from '@web3-react/core';
import React, { useContext, useEffect } from 'react';
import config from '../config/config';
import { ETH } from '../config/constants';
import walletContext from '../context/wallet/walletContext';
import TokenInput from './TokenInput';

const RemoveLiquidityAmount = ({ amount, setAmount, setInsufficientBalance }) => {
    const { selectedSymbol, getPair } = useContext(walletContext);

    const { account, library, chainId } = useWeb3React();

    const handleChange = async (e) => {
        const { name, value } = e.target;

        if (!value) {
            setInsufficientBalance(false);
            setAmount((prev) => ({
                ...prev,
                [name]: '',
                token1Current: '',
                token2Current: '',
            }));

            return;
        }

        if (value % 1 !== 0 && value.split('.')[1].length > 18) {
            return;
        }

        setAmount((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleMax = () => {
        if (!account || !amount.lpBalance) {
            return;
        }
        setAmount((prev) => ({
            ...prev,
            lpInput: prev.lpBalance.toExact(),
        }));
    };

    useEffect(() => {
        if (
            !amount.lpBalance ||
            !amount.token1 ||
            !amount.token2 ||
            !amount.lpInput ||
            !account ||
            !library
        ) {
            return;
        }

        if (Number(amount.lpInput) > Number(amount.lpBalance.toExact())) {
            setInsufficientBalance(true);
            return;
        }

        setInsufficientBalance(false);

        const inputValue = new TokenAmount(
            amount.lpBalance.token,
            parseUnits(amount.lpInput.toString(), 18)
        );

        const percentageOfPool = inputValue.divide(amount.lpBalance.raw);

        const token1Remainder = new TokenAmount(
            amount.token1.token,
            parseUnits(
                percentageOfPool
                    .multiply(parseUnits(amount.token1.toExact()))
                    .toFixed(
                        selectedSymbol === ETH
                            ? 10
                            : config.tokensByNetwork[chainId][0].decimals
                    ),
                selectedSymbol === ETH ? 18 : config.tokensByNetwork[chainId][0].decimals
            )
        );

        const token2Remainder = new TokenAmount(
            amount.token2.token,
            parseUnits(percentageOfPool.multiply(amount.token2.raw).toFixed(10), 18)
        );

        setAmount((prev) => ({
            ...prev,
            token1Current: token1Remainder,
            token2Current: token2Remainder,
        }));
        // eslint-disable-next-line
    }, [amount.lpInput, account, library]);

    useEffect(() => {
        if (!account || !library) {
            return;
        }

        (async () => {
            try {
                const pair = await getPair();

                const contract = new Contract(
                    pair.liquidityToken.address,
                    config.uniswap.abis.pair,
                    library.getSigner()
                );

                const token1 = pair.token0;
                const token2 = pair.token1;

                const totalSupply = await contract.totalSupply();
                const supplyToken = new TokenAmount(pair.liquidityToken, totalSupply);

                const userLPBalance = await contract.balanceOf(account);
                const userLiquidityToken = new TokenAmount(
                    pair.liquidityToken,
                    userLPBalance
                );

                const token1Pooled = await pair.getLiquidityValue(
                    token1,
                    supplyToken,
                    userLiquidityToken,
                    false
                );

                const token2Pooled = await pair.getLiquidityValue(
                    token2,
                    supplyToken,
                    userLiquidityToken,
                    false
                );

                setAmount((prev) => ({
                    ...prev,
                    lpBalance: userLiquidityToken,
                    token1: token1Pooled,
                    token2: token2Pooled,
                }));
            } catch (error) {
                console.log(error);
            }
        })();
        // eslint-disable-next-line
    }, [library, account]);

    return (
        <div className='amount-container'>
            <TokenInput
                label='Amount'
                token={amount.lpInput}
                balance={amount.lpBalance ? amount.lpBalance.toSignificant(6) : '0.00'}
                tokenName='lpInput'
                onChange={handleChange}
                onMax={handleMax}
            />
        </div>
    );
};

export default RemoveLiquidityAmount;
