import './App.scss';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import config from './config/config';
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import Home from './components/pages/Home';
import AddLiquidity from './components/pages/AddLiquidity';
import Stake from './components/pages/Stake';
import { useEffect } from 'react';
import WalletState from './context/wallet/WalletState';
import AppBackground from './components/layout/AppBackground';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import RemoveLiquidity from './components/pages/RemoveLiquidity';
import Modals from './components/modals/Modals';
import Countdown from './components/layout/Countdown';

function App() {
    useEffect(() => {
        const root = document.documentElement;

        const { primary, accent, backgroundPrimary, backgroundSecondary } = config.colors;

        root.style.setProperty('--primaryColor', primary);
        root.style.setProperty('--accentColor', accent);
        root.style.setProperty('--bgPrimary', backgroundPrimary);
        root.style.setProperty('--bgSecondary', backgroundSecondary);
    }, []);

    const getLibrary = (provider) => {
        const library = new Web3Provider(provider);
        library.pollingInterval = 12000;

        return library;
    };

    return (
        <Web3ReactProvider getLibrary={getLibrary}>
            <WalletState>
                <Router>
                    <Countdown>
                        <Navbar />
                        <AppBackground />
                        <main>
                            <div className='container'>
                                <Switch>
                                    <Route exact path='/' component={Home} />
                                    <Route
                                        exact
                                        path='/liquidity/add'
                                        component={AddLiquidity}
                                    />
                                    <Route
                                        exact
                                        path='/liquidity/remove'
                                        component={RemoveLiquidity}
                                    />
                                    <Route exact path='/stake' component={Stake} />
                                    <Redirect to='/' />
                                </Switch>
                            </div>
                            <Modals />
                        </main>
                        <Footer />
                    </Countdown>
                </Router>
            </WalletState>
        </Web3ReactProvider>
    );
}

export default App;
