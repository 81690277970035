// import Ledger from '../assets/icons/Ledger';
import MetaMask from '../assets/icons/MetaMask';
// import TrustWallet from '../assets/icons/TrustWallet';
import WalletConnect from '../assets/icons/WalletConnect';
import Telegram from '../assets/icons/telegram.png';
import Twitter from '../assets/icons/twitter.png';
import Medium from '../assets/icons/medium.png';
import {
    injectedConnector,
    // LedgerConnect,
    WalletConnector,
} from '../web3/connectors';
import { networks } from './constants';

const config = {
    appName: 'IX Swap',
    appSymbol: 'IXS',
    launchDate: 1631800800000,
    colors: {
        primary: '#CC3180',
        accent: '#fff',
        backgroundPrimary: '#1D0E2C',
        backgroundSecondary: 'linear-gradient(90.7deg, #6237DD 0%, #E13586 100%)',
    },
    socialMedias: [
        {
            name: 'Telegram',
            link: 'https://t.me/ixswapofficial',
            icon: Telegram,
        },
        {
            name: 'Twitter',
            link: 'https://twitter.com/IxSwap',
            icon: Twitter,
        },
        {
            name: 'Medium',
            link: 'https://ixswap.medium.com/',
            icon: Medium,
        },
    ],
    rewardsPerBlock: {
        eth: 0.905220102591611627,
        usdc: 0.8205128205, // currently not used
    },
    slippage: 0.005,
    miningAddress: '0xf062AE32ce31982eAf5b5badb040511b1546EDf2', // Mainnet
    // miningAddress: '0x2c90854D0088B63ebEC74bB6BA085Ae77C10Ed71', //   on Rinkeby
    poolIds: {
        pair1: 0, // ETH <> IXS on Mainnet
        pair2: 1, // Not used on Mainnet
        // pair1: 0, // currently WETH <> POP on Rinkeby
        // pair2: 1, // currently DAI <> POP on Rinkeby
    },
    walletConnections: [
        {
            name: 'MetaMask',
            icon: <MetaMask />,
            connector: injectedConnector,
        },
        // {
        //     name: 'TrustWallet',
        //     icon: <TrustWallet />,
        //     connector: injectedConnector,
        // },
        {
            name: 'Wallet Connect',
            icon: <WalletConnect />,
            connector: WalletConnector,
        },
        // {
        //     name: 'Ledger',
        //     icon: <Ledger />,
        //     connector: LedgerConnect,
        // },
    ],
    tokensByNetwork: {
        // Query GET https://api.coingecko.com/api/v3/coins/list for coingeckoID
        [networks.MainNet]: [
            {
                address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
                symbol: 'USDC',
                name: 'USD Coin',
                decimals: 6,
                coingeckoID: 'usd-coin',
            },
            {
                address: '0x73d7c860998CA3c01Ce8c808F5577d94d545d1b4',
                symbol: 'IXS',
                name: 'Ixs Token',
                decimals: 18,
                coingeckoID: 'ix-swap',
            },
        ],
        [networks.Rinkeby]: [
            {
                address: '0xc7AD46e0b8a400Bb3C915120d284AafbA8fc4735',
                symbol: 'Dai',
                name: 'Dai Stablecoin',
                decimals: 18,
                coingeckoID: 'dai',
            },
            {
                address: '0xCd1Acf3145a3598c9906463878DC8eF4e7074FD6',
                symbol: 'IXS',
                name: 'POP',
                decimals: 18,
                coingeckoID: 'pop',
            },
        ],
    },
    uniswap: {
        addresses: {
            router: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
            factory: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
        },
        abis: {
            factory: [
                {
                    inputs: [
                        {
                            internalType: 'address',
                            name: '_feeToSetter',
                            type: 'address',
                        },
                    ],
                    payable: false,
                    stateMutability: 'nonpayable',
                    type: 'constructor',
                },
                {
                    anonymous: false,
                    inputs: [
                        {
                            indexed: true,
                            internalType: 'address',
                            name: 'token0',
                            type: 'address',
                        },
                        {
                            indexed: true,
                            internalType: 'address',
                            name: 'token1',
                            type: 'address',
                        },
                        {
                            indexed: false,
                            internalType: 'address',
                            name: 'pair',
                            type: 'address',
                        },
                        {
                            indexed: false,
                            internalType: 'uint256',
                            name: '',
                            type: 'uint256',
                        },
                    ],
                    name: 'PairCreated',
                    type: 'event',
                },
                {
                    constant: true,
                    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
                    name: 'allPairs',
                    outputs: [{ internalType: 'address', name: '', type: 'address' }],
                    payable: false,
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [],
                    name: 'allPairsLength',
                    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
                    payable: false,
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    constant: false,
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'tokenA',
                            type: 'address',
                        },
                        {
                            internalType: 'address',
                            name: 'tokenB',
                            type: 'address',
                        },
                    ],
                    name: 'createPair',
                    outputs: [
                        {
                            internalType: 'address',
                            name: 'pair',
                            type: 'address',
                        },
                    ],
                    payable: false,
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [],
                    name: 'feeTo',
                    outputs: [{ internalType: 'address', name: '', type: 'address' }],
                    payable: false,
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [],
                    name: 'feeToSetter',
                    outputs: [{ internalType: 'address', name: '', type: 'address' }],
                    payable: false,
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [
                        { internalType: 'address', name: '', type: 'address' },
                        { internalType: 'address', name: '', type: 'address' },
                    ],
                    name: 'getPair',
                    outputs: [{ internalType: 'address', name: '', type: 'address' }],
                    payable: false,
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    constant: false,
                    inputs: [
                        {
                            internalType: 'address',
                            name: '_feeTo',
                            type: 'address',
                        },
                    ],
                    name: 'setFeeTo',
                    outputs: [],
                    payable: false,
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    constant: false,
                    inputs: [
                        {
                            internalType: 'address',
                            name: '_feeToSetter',
                            type: 'address',
                        },
                    ],
                    name: 'setFeeToSetter',
                    outputs: [],
                    payable: false,
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
            ],
            pair: [
                {
                    anonymous: false,
                    inputs: [
                        {
                            indexed: true,
                            internalType: 'address',
                            name: 'owner',
                            type: 'address',
                        },
                        {
                            indexed: true,
                            internalType: 'address',
                            name: 'spender',
                            type: 'address',
                        },
                        {
                            indexed: false,
                            internalType: 'uint256',
                            name: 'value',
                            type: 'uint256',
                        },
                    ],
                    name: 'Approval',
                    type: 'event',
                },
                {
                    anonymous: false,
                    inputs: [
                        {
                            indexed: true,
                            internalType: 'address',
                            name: 'sender',
                            type: 'address',
                        },
                        {
                            indexed: false,
                            internalType: 'uint256',
                            name: 'amount0',
                            type: 'uint256',
                        },
                        {
                            indexed: false,
                            internalType: 'uint256',
                            name: 'amount1',
                            type: 'uint256',
                        },
                        {
                            indexed: true,
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                    ],
                    name: 'Burn',
                    type: 'event',
                },
                {
                    anonymous: false,
                    inputs: [
                        {
                            indexed: true,
                            internalType: 'address',
                            name: 'sender',
                            type: 'address',
                        },
                        {
                            indexed: false,
                            internalType: 'uint256',
                            name: 'amount0',
                            type: 'uint256',
                        },
                        {
                            indexed: false,
                            internalType: 'uint256',
                            name: 'amount1',
                            type: 'uint256',
                        },
                    ],
                    name: 'Mint',
                    type: 'event',
                },
                {
                    anonymous: false,
                    inputs: [
                        {
                            indexed: true,
                            internalType: 'address',
                            name: 'sender',
                            type: 'address',
                        },
                        {
                            indexed: false,
                            internalType: 'uint256',
                            name: 'amount0In',
                            type: 'uint256',
                        },
                        {
                            indexed: false,
                            internalType: 'uint256',
                            name: 'amount1In',
                            type: 'uint256',
                        },
                        {
                            indexed: false,
                            internalType: 'uint256',
                            name: 'amount0Out',
                            type: 'uint256',
                        },
                        {
                            indexed: false,
                            internalType: 'uint256',
                            name: 'amount1Out',
                            type: 'uint256',
                        },
                        {
                            indexed: true,
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                    ],
                    name: 'Swap',
                    type: 'event',
                },
                {
                    anonymous: false,
                    inputs: [
                        {
                            indexed: false,
                            internalType: 'uint112',
                            name: 'reserve0',
                            type: 'uint112',
                        },
                        {
                            indexed: false,
                            internalType: 'uint112',
                            name: 'reserve1',
                            type: 'uint112',
                        },
                    ],
                    name: 'Sync',
                    type: 'event',
                },
                {
                    anonymous: false,
                    inputs: [
                        {
                            indexed: true,
                            internalType: 'address',
                            name: 'from',
                            type: 'address',
                        },
                        {
                            indexed: true,
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            indexed: false,
                            internalType: 'uint256',
                            name: 'value',
                            type: 'uint256',
                        },
                    ],
                    name: 'Transfer',
                    type: 'event',
                },
                {
                    constant: true,
                    inputs: [],
                    name: 'DOMAIN_SEPARATOR',
                    outputs: [
                        {
                            internalType: 'bytes32',
                            name: '',
                            type: 'bytes32',
                        },
                    ],
                    payable: false,
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [],
                    name: 'MINIMUM_LIQUIDITY',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: '',
                            type: 'uint256',
                        },
                    ],
                    payable: false,
                    stateMutability: 'pure',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [],
                    name: 'PERMIT_TYPEHASH',
                    outputs: [
                        {
                            internalType: 'bytes32',
                            name: '',
                            type: 'bytes32',
                        },
                    ],
                    payable: false,
                    stateMutability: 'pure',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'owner',
                            type: 'address',
                        },
                        {
                            internalType: 'address',
                            name: 'spender',
                            type: 'address',
                        },
                    ],
                    name: 'allowance',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: '',
                            type: 'uint256',
                        },
                    ],
                    payable: false,
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    constant: false,
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'spender',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'value',
                            type: 'uint256',
                        },
                    ],
                    name: 'approve',
                    outputs: [
                        {
                            internalType: 'bool',
                            name: '',
                            type: 'bool',
                        },
                    ],
                    payable: false,
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'owner',
                            type: 'address',
                        },
                    ],
                    name: 'balanceOf',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: '',
                            type: 'uint256',
                        },
                    ],
                    payable: false,
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    constant: false,
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                    ],
                    name: 'burn',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: 'amount0',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amount1',
                            type: 'uint256',
                        },
                    ],
                    payable: false,
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [],
                    name: 'decimals',
                    outputs: [
                        {
                            internalType: 'uint8',
                            name: '',
                            type: 'uint8',
                        },
                    ],
                    payable: false,
                    stateMutability: 'pure',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [],
                    name: 'factory',
                    outputs: [
                        {
                            internalType: 'address',
                            name: '',
                            type: 'address',
                        },
                    ],
                    payable: false,
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [],
                    name: 'getReserves',
                    outputs: [
                        {
                            internalType: 'uint112',
                            name: 'reserve0',
                            type: 'uint112',
                        },
                        {
                            internalType: 'uint112',
                            name: 'reserve1',
                            type: 'uint112',
                        },
                        {
                            internalType: 'uint32',
                            name: 'blockTimestampLast',
                            type: 'uint32',
                        },
                    ],
                    payable: false,
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    constant: false,
                    inputs: [
                        {
                            internalType: 'address',
                            name: '',
                            type: 'address',
                        },
                        {
                            internalType: 'address',
                            name: '',
                            type: 'address',
                        },
                    ],
                    name: 'initialize',
                    outputs: [],
                    payable: false,
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [],
                    name: 'kLast',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: '',
                            type: 'uint256',
                        },
                    ],
                    payable: false,
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    constant: false,
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                    ],
                    name: 'mint',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: 'liquidity',
                            type: 'uint256',
                        },
                    ],
                    payable: false,
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [],
                    name: 'name',
                    outputs: [
                        {
                            internalType: 'string',
                            name: '',
                            type: 'string',
                        },
                    ],
                    payable: false,
                    stateMutability: 'pure',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'owner',
                            type: 'address',
                        },
                    ],
                    name: 'nonces',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: '',
                            type: 'uint256',
                        },
                    ],
                    payable: false,
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    constant: false,
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'owner',
                            type: 'address',
                        },
                        {
                            internalType: 'address',
                            name: 'spender',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'value',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint8',
                            name: 'v',
                            type: 'uint8',
                        },
                        {
                            internalType: 'bytes32',
                            name: 'r',
                            type: 'bytes32',
                        },
                        {
                            internalType: 'bytes32',
                            name: 's',
                            type: 'bytes32',
                        },
                    ],
                    name: 'permit',
                    outputs: [],
                    payable: false,
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [],
                    name: 'price0CumulativeLast',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: '',
                            type: 'uint256',
                        },
                    ],
                    payable: false,
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [],
                    name: 'price1CumulativeLast',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: '',
                            type: 'uint256',
                        },
                    ],
                    payable: false,
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    constant: false,
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                    ],
                    name: 'skim',
                    outputs: [],
                    payable: false,
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    constant: false,
                    inputs: [
                        {
                            internalType: 'uint256',
                            name: 'amount0Out',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amount1Out',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'bytes',
                            name: 'data',
                            type: 'bytes',
                        },
                    ],
                    name: 'swap',
                    outputs: [],
                    payable: false,
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [],
                    name: 'symbol',
                    outputs: [
                        {
                            internalType: 'string',
                            name: '',
                            type: 'string',
                        },
                    ],
                    payable: false,
                    stateMutability: 'pure',
                    type: 'function',
                },
                {
                    constant: false,
                    inputs: [],
                    name: 'sync',
                    outputs: [],
                    payable: false,
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [],
                    name: 'token0',
                    outputs: [
                        {
                            internalType: 'address',
                            name: '',
                            type: 'address',
                        },
                    ],
                    payable: false,
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [],
                    name: 'token1',
                    outputs: [
                        {
                            internalType: 'address',
                            name: '',
                            type: 'address',
                        },
                    ],
                    payable: false,
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [],
                    name: 'totalSupply',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: '',
                            type: 'uint256',
                        },
                    ],
                    payable: false,
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    constant: false,
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'value',
                            type: 'uint256',
                        },
                    ],
                    name: 'transfer',
                    outputs: [
                        {
                            internalType: 'bool',
                            name: '',
                            type: 'bool',
                        },
                    ],
                    payable: false,
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    constant: false,
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'from',
                            type: 'address',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'value',
                            type: 'uint256',
                        },
                    ],
                    name: 'transferFrom',
                    outputs: [
                        {
                            internalType: 'bool',
                            name: '',
                            type: 'bool',
                        },
                    ],
                    payable: false,
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
            ],
            router: [
                {
                    inputs: [],
                    name: 'WETH',
                    outputs: [
                        {
                            internalType: 'address',
                            name: '',
                            type: 'address',
                        },
                    ],
                    stateMutability: 'pure',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'tokenA',
                            type: 'address',
                        },
                        {
                            internalType: 'address',
                            name: 'tokenB',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountADesired',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountBDesired',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountAMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountBMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256',
                        },
                    ],
                    name: 'addLiquidity',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountA',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountB',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'liquidity',
                            type: 'uint256',
                        },
                    ],
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'token',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountTokenDesired',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountTokenMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountETHMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256',
                        },
                    ],
                    name: 'addLiquidityETH',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountToken',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountETH',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'liquidity',
                            type: 'uint256',
                        },
                    ],
                    stateMutability: 'payable',
                    type: 'function',
                },
                {
                    inputs: [],
                    name: 'factory',
                    outputs: [
                        {
                            internalType: 'address',
                            name: '',
                            type: 'address',
                        },
                    ],
                    stateMutability: 'pure',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountOut',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'reserveIn',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'reserveOut',
                            type: 'uint256',
                        },
                    ],
                    name: 'getAmountIn',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountIn',
                            type: 'uint256',
                        },
                    ],
                    stateMutability: 'pure',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountIn',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'reserveIn',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'reserveOut',
                            type: 'uint256',
                        },
                    ],
                    name: 'getAmountOut',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountOut',
                            type: 'uint256',
                        },
                    ],
                    stateMutability: 'pure',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountOut',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address[]',
                            name: 'path',
                            type: 'address[]',
                        },
                    ],
                    name: 'getAmountsIn',
                    outputs: [
                        {
                            internalType: 'uint256[]',
                            name: 'amounts',
                            type: 'uint256[]',
                        },
                    ],
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountIn',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address[]',
                            name: 'path',
                            type: 'address[]',
                        },
                    ],
                    name: 'getAmountsOut',
                    outputs: [
                        {
                            internalType: 'uint256[]',
                            name: 'amounts',
                            type: 'uint256[]',
                        },
                    ],
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountA',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'reserveA',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'reserveB',
                            type: 'uint256',
                        },
                    ],
                    name: 'quote',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountB',
                            type: 'uint256',
                        },
                    ],
                    stateMutability: 'pure',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'tokenA',
                            type: 'address',
                        },
                        {
                            internalType: 'address',
                            name: 'tokenB',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'liquidity',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountAMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountBMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256',
                        },
                    ],
                    name: 'removeLiquidity',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountA',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountB',
                            type: 'uint256',
                        },
                    ],
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'token',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'liquidity',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountTokenMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountETHMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256',
                        },
                    ],
                    name: 'removeLiquidityETH',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountToken',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountETH',
                            type: 'uint256',
                        },
                    ],
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'token',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'liquidity',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountTokenMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountETHMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256',
                        },
                    ],
                    name: 'removeLiquidityETHSupportingFeeOnTransferTokens',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountETH',
                            type: 'uint256',
                        },
                    ],
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'token',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'liquidity',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountTokenMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountETHMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256',
                        },
                        {
                            internalType: 'bool',
                            name: 'approveMax',
                            type: 'bool',
                        },
                        {
                            internalType: 'uint8',
                            name: 'v',
                            type: 'uint8',
                        },
                        {
                            internalType: 'bytes32',
                            name: 'r',
                            type: 'bytes32',
                        },
                        {
                            internalType: 'bytes32',
                            name: 's',
                            type: 'bytes32',
                        },
                    ],
                    name: 'removeLiquidityETHWithPermit',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountToken',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountETH',
                            type: 'uint256',
                        },
                    ],
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'token',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'liquidity',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountTokenMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountETHMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256',
                        },
                        {
                            internalType: 'bool',
                            name: 'approveMax',
                            type: 'bool',
                        },
                        {
                            internalType: 'uint8',
                            name: 'v',
                            type: 'uint8',
                        },
                        {
                            internalType: 'bytes32',
                            name: 'r',
                            type: 'bytes32',
                        },
                        {
                            internalType: 'bytes32',
                            name: 's',
                            type: 'bytes32',
                        },
                    ],
                    name: 'removeLiquidityETHWithPermitSupportingFeeOnTransferTokens',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountETH',
                            type: 'uint256',
                        },
                    ],
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'tokenA',
                            type: 'address',
                        },
                        {
                            internalType: 'address',
                            name: 'tokenB',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'liquidity',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountAMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountBMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256',
                        },
                        {
                            internalType: 'bool',
                            name: 'approveMax',
                            type: 'bool',
                        },
                        {
                            internalType: 'uint8',
                            name: 'v',
                            type: 'uint8',
                        },
                        {
                            internalType: 'bytes32',
                            name: 'r',
                            type: 'bytes32',
                        },
                        {
                            internalType: 'bytes32',
                            name: 's',
                            type: 'bytes32',
                        },
                    ],
                    name: 'removeLiquidityWithPermit',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountA',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountB',
                            type: 'uint256',
                        },
                    ],
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountOut',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address[]',
                            name: 'path',
                            type: 'address[]',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256',
                        },
                    ],
                    name: 'swapETHForExactTokens',
                    outputs: [
                        {
                            internalType: 'uint256[]',
                            name: 'amounts',
                            type: 'uint256[]',
                        },
                    ],
                    stateMutability: 'payable',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountOutMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address[]',
                            name: 'path',
                            type: 'address[]',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256',
                        },
                    ],
                    name: 'swapExactETHForTokens',
                    outputs: [
                        {
                            internalType: 'uint256[]',
                            name: 'amounts',
                            type: 'uint256[]',
                        },
                    ],
                    stateMutability: 'payable',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountOutMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address[]',
                            name: 'path',
                            type: 'address[]',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256',
                        },
                    ],
                    name: 'swapExactETHForTokensSupportingFeeOnTransferTokens',
                    outputs: [],
                    stateMutability: 'payable',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountIn',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountOutMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address[]',
                            name: 'path',
                            type: 'address[]',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256',
                        },
                    ],
                    name: 'swapExactTokensForETH',
                    outputs: [
                        {
                            internalType: 'uint256[]',
                            name: 'amounts',
                            type: 'uint256[]',
                        },
                    ],
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountIn',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountOutMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address[]',
                            name: 'path',
                            type: 'address[]',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256',
                        },
                    ],
                    name: 'swapExactTokensForETHSupportingFeeOnTransferTokens',
                    outputs: [],
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountIn',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountOutMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address[]',
                            name: 'path',
                            type: 'address[]',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256',
                        },
                    ],
                    name: 'swapExactTokensForTokens',
                    outputs: [
                        {
                            internalType: 'uint256[]',
                            name: 'amounts',
                            type: 'uint256[]',
                        },
                    ],
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountIn',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountOutMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address[]',
                            name: 'path',
                            type: 'address[]',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256',
                        },
                    ],
                    name: 'swapExactTokensForTokensSupportingFeeOnTransferTokens',
                    outputs: [],
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountOut',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountInMax',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address[]',
                            name: 'path',
                            type: 'address[]',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256',
                        },
                    ],
                    name: 'swapTokensForExactETH',
                    outputs: [
                        {
                            internalType: 'uint256[]',
                            name: 'amounts',
                            type: 'uint256[]',
                        },
                    ],
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountOut',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountInMax',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address[]',
                            name: 'path',
                            type: 'address[]',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256',
                        },
                    ],
                    name: 'swapTokensForExactTokens',
                    outputs: [
                        {
                            internalType: 'uint256[]',
                            name: 'amounts',
                            type: 'uint256[]',
                        },
                    ],
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
            ],
        },
    },
    erc20abi: [
        {
            constant: false,
            inputs: [
                {
                    name: '_spender',
                    type: 'address',
                },
                {
                    name: '_value',
                    type: 'uint256',
                },
            ],
            name: 'approve',
            outputs: [
                {
                    name: 'success',
                    type: 'bool',
                },
            ],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            constant: false,
            inputs: [
                {
                    name: '_to',
                    type: 'address',
                },
                {
                    name: '_value',
                    type: 'uint256',
                },
            ],
            name: 'showMeTheMoney',
            outputs: [],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            constant: false,
            inputs: [
                {
                    name: '_to',
                    type: 'address',
                },
                {
                    name: '_value',
                    type: 'uint256',
                },
            ],
            name: 'transfer',
            outputs: [
                {
                    name: 'success',
                    type: 'bool',
                },
            ],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            constant: false,
            inputs: [
                {
                    name: '_from',
                    type: 'address',
                },
                {
                    name: '_to',
                    type: 'address',
                },
                {
                    name: '_value',
                    type: 'uint256',
                },
            ],
            name: 'transferFrom',
            outputs: [
                {
                    name: 'success',
                    type: 'bool',
                },
            ],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    name: '_from',
                    type: 'address',
                },
                {
                    indexed: true,
                    name: '_to',
                    type: 'address',
                },
                {
                    indexed: false,
                    name: '_value',
                    type: 'uint256',
                },
            ],
            name: 'Transfer',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    name: '_owner',
                    type: 'address',
                },
                {
                    indexed: true,
                    name: '_spender',
                    type: 'address',
                },
                {
                    indexed: false,
                    name: '_value',
                    type: 'uint256',
                },
            ],
            name: 'Approval',
            type: 'event',
        },
        {
            constant: true,
            inputs: [
                {
                    name: '_owner',
                    type: 'address',
                },
                {
                    name: '_spender',
                    type: 'address',
                },
            ],
            name: 'allowance',
            outputs: [
                {
                    name: 'remaining',
                    type: 'uint256',
                },
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: true,
            inputs: [
                {
                    name: '_owner',
                    type: 'address',
                },
            ],
            name: 'balanceOf',
            outputs: [
                {
                    name: 'balance',
                    type: 'uint256',
                },
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: true,
            inputs: [],
            name: 'decimals',
            outputs: [
                {
                    name: '',
                    type: 'uint256',
                },
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: true,
            inputs: [],
            name: 'name',
            outputs: [
                {
                    name: '',
                    type: 'string',
                },
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: true,
            inputs: [],
            name: 'symbol',
            outputs: [
                {
                    name: '',
                    type: 'string',
                },
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: true,
            inputs: [],
            name: 'totalSupply',
            outputs: [
                {
                    name: '',
                    type: 'uint256',
                },
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
    ],
    miningAbi: [
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '_tokenPerBlock',
                    type: 'uint256',
                },
                {
                    internalType: 'contract IERC20',
                    name: '_token',
                    type: 'address',
                },
                {
                    internalType: 'bool',
                    name: '_withUpdate',
                    type: 'bool',
                },
            ],
            name: 'add',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_rewardAddress',
                    type: 'address',
                },
            ],
            name: 'changeRewardAddress',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '_poolId',
                    type: 'uint256',
                },
            ],
            name: 'claimReward',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '_poolId',
                    type: 'uint256',
                },
                {
                    internalType: 'uint256',
                    name: '_amount',
                    type: 'uint256',
                },
            ],
            name: 'deposit',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_tokenAddress',
                    type: 'address',
                },
                {
                    internalType: 'address',
                    name: '_tokenRewardsAddress',
                    type: 'address',
                },
                {
                    internalType: 'uint256',
                    name: '_startBlock',
                    type: 'uint256',
                },
            ],
            stateMutability: 'nonpayable',
            type: 'constructor',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'user',
                    type: 'address',
                },
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'poolId',
                    type: 'uint256',
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256',
                },
            ],
            name: 'Deposit',
            type: 'event',
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '_poolId',
                    type: 'uint256',
                },
            ],
            name: 'emergencyWithdraw',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'user',
                    type: 'address',
                },
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'poolId',
                    type: 'uint256',
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256',
                },
            ],
            name: 'EmergencyWithdraw',
            type: 'event',
        },
        {
            inputs: [],
            name: 'massUpdatePools',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'previousOwner',
                    type: 'address',
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address',
                },
            ],
            name: 'OwnershipTransferred',
            type: 'event',
        },
        {
            inputs: [],
            name: 'renounceOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'user',
                    type: 'address',
                },
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'poolId',
                    type: 'uint256',
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256',
                },
            ],
            name: 'SendTokenReward',
            type: 'event',
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '_poolId',
                    type: 'uint256',
                },
                {
                    internalType: 'uint256',
                    name: '_tokenPerBlock',
                    type: 'uint256',
                },
                {
                    internalType: 'bool',
                    name: '_withUpdate',
                    type: 'bool',
                },
            ],
            name: 'set',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address',
                },
            ],
            name: 'transferOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '_poolId',
                    type: 'uint256',
                },
            ],
            name: 'updatePool',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '_poolId',
                    type: 'uint256',
                },
                {
                    internalType: 'uint256',
                    name: '_amount',
                    type: 'uint256',
                },
            ],
            name: 'withdraw',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'user',
                    type: 'address',
                },
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'poolId',
                    type: 'uint256',
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256',
                },
            ],
            name: 'Withdraw',
            type: 'event',
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '_from',
                    type: 'uint256',
                },
                {
                    internalType: 'uint256',
                    name: '_to',
                    type: 'uint256',
                },
                {
                    internalType: 'uint256',
                    name: '_tokenPerBlock',
                    type: 'uint256',
                },
            ],
            name: 'getPoolReward',
            outputs: [
                {
                    internalType: 'uint256',
                    name: 'rewards',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'owner',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '_poolId',
                    type: 'uint256',
                },
                {
                    internalType: 'address',
                    name: '_user',
                    type: 'address',
                },
            ],
            name: 'pendingReward',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            name: 'poolInfo',
            outputs: [
                {
                    internalType: 'contract IERC20',
                    name: 'token',
                    type: 'address',
                },
                {
                    internalType: 'uint256',
                    name: 'tokenPerBlock',
                    type: 'uint256',
                },
                {
                    internalType: 'uint256',
                    name: 'lastRewardBlock',
                    type: 'uint256',
                },
                {
                    internalType: 'uint256',
                    name: 'accTokenPerShare',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'poolLength',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'START_BLOCK',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'token',
            outputs: [
                {
                    internalType: 'contract IERC20',
                    name: '',
                    type: 'address',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'tokenRewardsAddress',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address',
                },
            ],
            name: 'tokenToPoolId',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
                {
                    internalType: 'address',
                    name: '',
                    type: 'address',
                },
            ],
            name: 'userInfo',
            outputs: [
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256',
                },
                {
                    internalType: 'uint256',
                    name: 'rewardDebt',
                    type: 'uint256',
                },
                {
                    internalType: 'uint256',
                    name: 'rewardDebtAtBlock',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
    ],
};

export default config;
