import React, { useContext } from 'react';
import ReactModal from 'react-modal';
import CrossIcon from '../../assets/icons/CrossIcon';
import walletContext from '../../context/wallet/walletContext';
import Loading from '../layout/Loading';

const Confirmation = (props) => {
    const { modal, closeModal } = useContext(walletContext);

    const onClose = () => {
        closeModal('confirmation');
    };

    return (
        <ReactModal
            {...props}
            ariaHideApp={false}
            isOpen={modal.confirmation}
            overlayClassName='modal-overlay'
            className='modal-content confirmation'
            shouldCloseOnOverlayClick={true}
            onRequestClose={onClose}
        >
            <div className='header'>
                <div className='cross-container' onClick={onClose}>
                    <CrossIcon />
                </div>
            </div>
            <div className='content'>
                <div className='loading-container'>
                    <Loading />
                </div>
                <p>Waiting For Confirmation</p>
            </div>
        </ReactModal>
    );
};

export default Confirmation;
