import React, { Fragment } from 'react';
import Confirmation from './Confirmation';
import TransactionSuccessful from './TransactionSuccessful';
import Warning from './Warning';

const Modals = () => {
    return (
        <Fragment>
            <Confirmation />
            <TransactionSuccessful />
            <Warning />
        </Fragment>
    );
};

export default Modals;
