import React from 'react';

const Tab = ({ icon, label, active, onClick }) => {
    return (
        <button
            type='button'
            className={`tab ${active ? 'active' : ''}`}
            onClick={onClick}
        >
            {icon}
            <h3>{label}</h3>
        </button>
    );
};

export default Tab;
