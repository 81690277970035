import React from 'react';

const EthIcon = () => {
    return (
        <svg
            width='25'
            height='38'
            viewBox='0 0 25 38'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M12.4965 0L12.4965 25.9006L24.9923 19.0974L12.4965 0Z'
                fill='white'
                fillOpacity='0.3'
            />
            <path d='M12.4961 0L0 19.0974L12.4961 13.8658V0Z' fill='white' />
            <path
                d='M12.4966 28.0807L12.3427 28.2536V37.0859L12.4966 37.4999L25 21.2809L12.4966 28.0807Z'
                fill='white'
                fillOpacity='0.4'
            />
            <path
                d='M12.4961 37.4999V28.0807L0 21.2809L12.4961 37.4999Z'
                fill='white'
            />
            <path
                d='M12.4967 25.9002L24.9925 19.0969L12.4967 13.8654V25.9002Z'
                fill='white'
                fillOpacity='0.3'
            />
            <path
                d='M0 19.0969L12.4961 25.9002V13.8654L0 19.0969Z'
                fill='white'
                fillOpacity='0.8'
            />
        </svg>
    );
};

export default EthIcon;
