import React from 'react';

const GradientBorderButton = ({ buttonComponent, buttonText, ...rest }) => (
    <div className='button-container' {...rest}>
        {buttonComponent}
        {buttonText}
    </div>
);

export default GradientBorderButton;
