import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import UniswapIcon from '../../assets/icons/UniswapIcon';
import WalletIcon from '../../assets/icons/WalletIcon';
import ConnectWallet from '../modals/ConnectWallet';
import ExternalLink from '../reusable/ExternalLink';
import { useWeb3React } from '@web3-react/core';
import { InfuraConnector } from '../../web3/connectors';
import { networks } from '../../config/constants';
import walletContext from '../../context/wallet/walletContext';
import Logo from '../../assets/logo/Logo';

const Navbar = () => {
    const [open, setOpen] = useState(false);
    const [sticky, setSticky] = useState(false);

    const { chainId, account, activate } = useWeb3React();

    const { openModal, closeModal } = useContext(walletContext);

    useEffect(() => {
        activate(InfuraConnector);

        const scrollListener = () => {
            if (window.scrollY > 10) {
                setSticky(true);
                return;
            }
            setSticky(false);
        };

        window.addEventListener('scroll', scrollListener);

        return () => {
            window.removeEventListener('scroll', scrollListener);
        };

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (chainId !== networks.MainNet) {
            openModal('warning');
            return;
        }

        closeModal('warning');

        // eslint-disable-next-line
    }, [chainId]);

    return (
        <header className={sticky ? 'sticky' : ''}>
            <div className='container'>
                <div className='inner__left'>
                    <Link to='/'>
                        <Logo />
                    </Link>
                </div>
                <div className='inner__right'>
                    <ExternalLink
                        to='https://app.uniswap.org/#/add/v2/ETH/0x73d7c860998CA3c01Ce8c808F5577d94d545d1b4'
                        className='uniswap-container'
                    >
                        <UniswapIcon />
                    </ExternalLink>
                    <button
                        type='button'
                        className='wallet-container'
                        onClick={() => setOpen(true)}
                    >
                        <WalletIcon />
                        <span>
                            {account ? (
                                `${account.slice(0, 6)}...${account.slice(-4)}`
                            ) : (
                                <Fragment>
                                    Connect <span>Wallet</span>
                                </Fragment>
                            )}
                        </span>
                    </button>
                </div>
            </div>
            <ConnectWallet
                ariaHideApp={false}
                isOpen={open}
                onRequestClose={() => setOpen(false)}
            />
        </header>
    );
};

export default Navbar;
