import React from 'react';

const Wallet = () => {
    return (
        <svg
            width='35'
            height='34'
            viewBox='0 0 35 34'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M8.64653 6.7362L22.3562 2.99403L21.6302 1.52651C21.1552 0.572623 19.9966 0.178711 19.0427 0.653723L6.76965 6.7362H8.64653Z'
                fill='#F96229'
            />
            <path
                d='M26.6121 3.10217C26.4422 3.10217 26.2723 3.12534 26.1023 3.17169L22.8816 4.0522L13.0453 6.73621H24.21H29.076L28.4735 4.52721C28.2418 3.66601 27.4617 3.10217 26.6121 3.10217Z'
                fill='#F96229'
            />
            <path
                d='M31.088 8.08783H30.6478H30.0492H29.4506H24.882H8.09433H5.89305H4.03934H3.69564H2.54479C1.93461 8.08783 1.39009 8.36975 1.03479 8.81386C0.872594 9.01855 0.749013 9.25412 0.679499 9.51287C0.637019 9.67507 0.609985 9.84499 0.609985 10.0188V10.2505V12.4518V31.5334C0.609985 32.5993 1.47505 33.4643 2.54093 33.4643H31.0842C32.1501 33.4643 33.0151 32.5993 33.0151 31.5334V26.146H21.5569C19.7457 26.146 18.2743 24.6746 18.2743 22.8634V21.0947V20.4961V19.8975V18.569C18.2743 17.6808 18.6295 16.8737 19.205 16.2828C19.7148 15.7575 20.3983 15.4022 21.163 15.3134C21.2904 15.298 21.4217 15.2903 21.553 15.2903H31.4086H32.0072H32.6057H33.0151V10.0188C33.019 8.95289 32.1539 8.08783 31.088 8.08783Z'
                fill='#F96229'
            />
            <path
                d='M34.3706 17.1362C34.1775 16.9586 33.9496 16.8234 33.6947 16.7346C33.4977 16.669 33.2892 16.6304 33.0691 16.6304H33.0189H32.9803H32.3817H30.2229H21.5568C20.4909 16.6304 19.6259 17.4954 19.6259 18.5613V19.5229V20.1215V20.7201V22.8596C19.6259 23.9254 20.4909 24.7905 21.5568 24.7905H33.0189H33.0691C33.2892 24.7905 33.4978 24.7519 33.6947 24.6862C33.9496 24.6013 34.1775 24.4622 34.3706 24.2846C34.7567 23.9332 35 23.4234 35 22.8596V18.5613C35 17.9974 34.7567 17.4876 34.3706 17.1362ZM25.5886 21.0947C25.5886 21.6276 25.1561 22.0602 24.6231 22.0602H23.9821C23.4491 22.0602 23.0166 21.6276 23.0166 21.0947V20.4536C23.0166 20.1447 23.1595 19.8704 23.3873 19.6966C23.5534 19.5692 23.7581 19.4881 23.9821 19.4881H24.1443H24.6231C25.1561 19.4881 25.5886 19.9206 25.5886 20.4536V21.0947Z'
                fill='#F96229'
            />
        </svg>
    );
};

export default Wallet;
