import { Contract } from '@ethersproject/contracts';
import { formatUnits, parseUnits } from '@ethersproject/units';
import { WETH } from '@uniswap/sdk';
import { useWeb3React } from '@web3-react/core';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import useSWR from 'swr';
import CrossIcon from '../../assets/icons/CrossIcon';
import config from '../../config/config';
import { ETH } from '../../config/constants';
import walletContext from '../../context/wallet/walletContext';
import { calculateMargin, fetcher } from '../../web3/utils';
import { MaxUint256 } from '@ethersproject/constants';
import { Redirect } from 'react-router';
const Stake = () => {
    const [amount, setAmount] = useState();
    const [approved, setApproved] = useState(true);
    const [allowance, setAllowance] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [insufficientLP, setInsufficientLP] = useState(false);

    const stakeButtons = [
        {
            label: isLoading && !approved ? 'Approving...' : 'Approve',
            onClick: () => approveLP(),
            disabled: insufficientLP || approved,
        },
        {
            label: isLoading && approved ? 'Staking...' : 'Stake',

            onClick: () => stakeLP(),
            disabled: insufficientLP || !approved || !amount,
        },
    ];

    const { selectedSymbol, openModal, closeModal, setModalMessage } =
        useContext(walletContext);

    const { chainId, library, account } = useWeb3React();

    const { data: pairAddress } = useSWR(
        [
            config.uniswap.addresses.factory,
            'getPair',

            selectedSymbol === ETH
                ? WETH[chainId]?.address
                : config.tokensByNetwork[chainId]?.[0].address ?? '',
            config.tokensByNetwork[chainId]?.[1].address ?? '',
        ],
        {
            fetcher: fetcher(library, config.uniswap.abis.factory),
        }
    );

    const { data: lpBalance } = useSWR([pairAddress ?? '', 'balanceOf', account], {
        fetcher: fetcher(library, config.erc20abi),
    });

    const handleChange = (e) => {
        const { value } = e.target;

        setAmount(value);
    };

    const formatBalance = (balance) => formatUnits(balance, 18);

    const onMax = () => {
        if (!account) return;
        setAmount(formatBalance(lpBalance));
    };

    const approveLP = async () => {
        const contract = new Contract(pairAddress, config.erc20abi, library.getSigner());

        try {
            setIsLoading(true);

            let useExact = false;

            const estimatedGas = await contract.estimateGas
                .approve(config.miningAddress, MaxUint256)
                .catch(() => {
                    useExact = true;
                    return contract.approve(
                        config.miningAddress,
                        parseUnits(amount.toString())
                    );
                });

            const approve = await contract.approve(
                config.miningAddress,
                useExact ? parseUnits(amount.toString()) : MaxUint256,
                {
                    gasLimit: calculateMargin(estimatedGas),
                }
            );

            await approve.wait();

            setApproved(true);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const stakeLP = async () => {
        openModal('confirmation');
        try {
            setIsLoading(true);

            const contract = new Contract(
                config.miningAddress,
                config.miningAbi,
                library.getSigner()
            );

            const deposit = await contract.deposit(
                config.poolIds.pair1,
                parseUnits(amount.toString())
            );

            closeModal('confirmation');
            setModalMessage(deposit.hash);
            openModal('transactionSuccess');

            await deposit.wait();

            setAmount('');
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        // check approval state
        if (!library || !pairAddress || !account) return;

        (async () => {
            const contract = new Contract(
                pairAddress,
                config.erc20abi,
                library.getSigner()
            );

            const allowance = await contract.allowance(account, config.miningAddress);

            const formattedAllowance = formatUnits(allowance.toString(), 18);

            setAllowance(formattedAllowance);
        })();
    }, [pairAddress, library, account]);

    useEffect(() => {
        if (!chainId || !lpBalance || !amount) {
            return;
        }

        if (parseUnits(amount).gt(parseUnits(formatBalance(lpBalance)))) {
            setInsufficientLP(true);
            return;
        }

        setInsufficientLP(false);

        // eslint-disable-next-line
    }, [lpBalance, amount]);

    useEffect(() => {
        if (amount && parseFloat(amount) > allowance) {
            setApproved(false);
            return;
        }

        if (allowance > 0) {
            setApproved(true);
        }
    }, [allowance, amount]);

    if (!account) {
        return <Redirect to='/' />;
    }

    return (
        <section id='stake'>
            <div className='container'>
                <div className='stake-form'>
                    <div className='header'>
                        <h2>Stake</h2>
                        <Link to='/'>
                            <CrossIcon />
                        </Link>
                    </div>
                    <div className='content'>
                        <div className='lp-balance-container'>
                            <p>
                                Liquidity Pool Token Balance:{' '}
                                <span>
                                    {lpBalance ? formatBalance(lpBalance) : '0.000'}
                                </span>
                            </p>
                        </div>
                        <div className='input-container'>
                            <input
                                type='number'
                                value={amount || ''}
                                onChange={handleChange}
                                placeholder='0.0'
                            />
                            <button type='button' onClick={onMax}>
                                max
                            </button>
                        </div>
                        <div className='stake-buttons-container'>
                            {amount ? (
                                !insufficientLP ? (
                                    stakeButtons.map(
                                        ({ label, onClick, disabled }, index) => (
                                            <div key={label} className='stake-button-row'>
                                                <div className='step'>{index + 1}</div>
                                                <button
                                                    type='button'
                                                    onClick={onClick}
                                                    disabled={disabled}
                                                >
                                                    {label}
                                                </button>
                                            </div>
                                        )
                                    )
                                ) : (
                                    <div className='stake-button-row single'>
                                        <button disabled>Insufficient LP Balance</button>
                                    </div>
                                )
                            ) : (
                                <div className='stake-button-row single'>
                                    <button disabled>Enter an amount</button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Stake;
