import { Contract } from '@ethersproject/contracts';
import { formatUnits } from '@ethersproject/units';
import { useWeb3React } from '@web3-react/core';
import React, { useContext, useState } from 'react';
import useSWR from 'swr';
import Prompt from '../components/modals/Prompt';
import GradientBorderButton from '../components/reusable/GradientBorderButton';
import config from '../config/config';
// import { ETH } from '../config/constants';
import walletContext from '../context/wallet/walletContext';
import { fetcher } from '../web3/utils';

const Claim = () => {
    const [open, setOpen] = useState(false);

    const {
        // selectedSymbol,
        openModal,
        closeModal,
        setModalMessage,
    } = useContext(walletContext);

    const { chainId, library, account } = useWeb3React();

    const poolID = config.poolIds.pair1;

    const { data: rewards } = useSWR(
        [config.miningAddress, 'pendingReward', poolID, account],
        {
            fetcher: fetcher(library, config.miningAbi),
        }
    );

    const claim = async () => {
        closeClaimModal();
        openModal('confirmation');
        const contract = new Contract(
            config.miningAddress,
            config.miningAbi,
            library.getSigner()
        );

        try {
            const claim = await contract.claimReward(0);
            setModalMessage(claim.hash);
            openModal('transactionSuccess');
        } catch (error) {
            console.log(error);
        } finally {
            closeModal('confirmation');
        }
    };

    const closeClaimModal = () => {
        setOpen(false);
    };

    return (
        <div className='claim-container'>
            <p>
                Rewards:
                <span>
                    <span className='amount'>
                        {rewards
                            ? parseFloat(formatUnits(rewards.toString(), 18)).toFixed(4)
                            : '0.00'}
                    </span>
                    {config.tokensByNetwork[chainId][1].symbol}
                </span>
            </p>

            <GradientBorderButton
                buttonComponent={<button type='button' />}
                buttonText='Claim'
                onClick={() => setOpen(true)}
            />
            <Prompt
                ariaHideApp={false}
                isOpen={open}
                title={`Are you sure you want to claim the rewards?`}
                onRequestClose={closeClaimModal}
                onConfirm={claim}
            />
        </div>
    );
};

export default Claim;
