import React from 'react';

const SwapIcon = () => {
    return (
        <svg
            width='70'
            height='27'
            viewBox='0 0 70 27'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M16.8549 1.71721C16.8549 0.254639 15.1409 -0.535919 14.0284 0.413557L0.701638 11.7876C-0.233879 12.586 -0.23388 14.0317 0.701637 14.8301L14.0283 26.2041C15.1408 27.1536 16.8549 26.363 16.8549 24.9004C16.8549 23.9538 17.6223 23.1864 18.5689 23.1864H25.5528C26.2807 23.1864 26.9512 22.7909 27.3033 22.1538L36.0102 6.39849C36.7468 5.06551 35.7827 3.43112 34.2597 3.43112H18.5688C17.6223 3.43112 16.8549 2.66378 16.8549 1.71721Z'
                fill='#171A2A'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M52.307 24.9005C52.307 26.3631 54.0211 27.1537 55.1337 26.2042L68.4603 14.8302C69.3959 14.0317 69.3959 12.5861 68.4604 11.7876L55.1336 0.413622C54.0211 -0.535866 52.307 0.254702 52.307 1.71729C52.307 2.66387 51.5397 3.43122 50.5931 3.43122H43.6092C42.8813 3.43122 42.2108 3.82673 41.8587 4.46385L33.1518 20.2192C32.4152 21.5521 33.3793 23.1865 34.9023 23.1865H50.5931C51.5397 23.1865 52.307 23.9539 52.307 24.9005Z'
                fill='#171A2A'
            />
        </svg>
    );
};

export default SwapIcon;
