import { isAddress } from '@ethersproject/address';
import { BigNumber } from '@ethersproject/bignumber';
import { Contract } from '@ethersproject/contracts';
import config from '../config/config';

export const fetcher = (library, abi) => (...args) => {
    const [arg1, arg2, ...params] = args;

    if (isAddress(arg1)) {
        const address = arg1;
        const method = arg2;

        const contract = new Contract(
            address,
            abi,
            library.provider.isMetaMask
                ? library.getSigner()
                : library.getSigner(config.miningAddress)
        );
        return contract[method](...params);
    }

    const method = arg1;

    return library[method](arg2, ...params);
};

export const formatNumber = (num) =>
    num % 1 === 0 ? Number(num).toPrecision(10) : Number(num).toFixed(6);

export const calculateMargin = (value) =>
    value
        .mul(BigNumber.from(10000).add(BigNumber.from(1000)))
        .div(BigNumber.from(10000));

export const getChainName = (chainId) => {
    switch (chainId) {
        case 1:
            return 'mainnet';
        case 3:
            return 'ropsten';
        case 4:
            return 'rinkeby';
        case 5:
            return 'goerli';
        case 42:
            return 'kovan';
        default:
            return '';
    }
};
