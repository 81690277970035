import React, { useContext, useEffect, useState } from 'react';
import { formatUnits } from '@ethersproject/units';
import { WETH } from '@uniswap/sdk';
import { useWeb3React } from '@web3-react/core';
import { BLOCKS_PER_DAY, ETH } from '../../config/constants';
import { fetcher } from '../../web3/utils';
import useSWR from 'swr';
import config from '../../config/config';
import walletContext from '../../context/wallet/walletContext';
import { useRef } from 'react';

const Apy = () => {
    const { chainId, library } = useWeb3React();
    const isMounted = useRef(true);

    const { selectedSymbol, getTokenPriceUSD } = useContext(walletContext);

    const [apy, setApy] = useState(0);

    const { data: pairAddress } = useSWR(
        [
            config.uniswap.addresses.factory,
            'getPair',

            selectedSymbol === ETH
                ? WETH[chainId]?.address
                : config.tokensByNetwork[chainId]?.[0].address ?? '',
            config.tokensByNetwork[chainId]?.[1].address ?? '',
        ],
        {
            fetcher: fetcher(library, config.uniswap.abis.factory),
        }
    );

    const { data: reserves } = useSWR([pairAddress, 'getReserves'], {
        fetcher: fetcher(library, config.uniswap.abis.pair),
    });

    const { data: totalSupply } = useSWR([pairAddress, 'totalSupply'], {
        fetcher: fetcher(library, config.uniswap.abis.pair),
    });

    const { data: lmBalance } = useSWR([pairAddress, 'balanceOf', config.miningAddress], {
        fetcher: fetcher(library, config.erc20abi),
    });

    useEffect(() => {
        setApy(0);
        isMounted.current = true;

        if (!chainId || !reserves || !totalSupply || !lmBalance) {
            return;
        }

        (async () => {
            try {
                const etherPrice = await getTokenPriceUSD('ethereum');

                const tokenPrice = await getTokenPriceUSD(
                    config.tokensByNetwork[chainId]?.[0].coingeckoID
                );

                const token1_price = selectedSymbol === ETH ? etherPrice : tokenPrice;

                const token2_price = await getTokenPriceUSD(
                    config.tokensByNetwork[chainId]?.[1].coingeckoID
                );

                const token1Reserve = parseFloat(
                    formatUnits(
                        reserves[0] ?? 0,
                        selectedSymbol === ETH
                            ? 18
                            : config.tokensByNetwork[chainId]?.[0].decimals
                    )
                );

                const token2Reserve = parseFloat(
                    formatUnits(
                        reserves[1] ?? 0,
                        config.tokensByNetwork[chainId]?.[1].decimals
                    )
                );

                const rewardsPerBlock =
                    selectedSymbol === ETH
                        ? config.rewardsPerBlock.eth
                        : config.rewardsPerBlock.usdc;

                const depositLPRatio = formatUnits(lmBalance) / formatUnits(totalSupply);

                const dpr =
                    (rewardsPerBlock * token2_price * BLOCKS_PER_DAY) /
                    (depositLPRatio * token2Reserve * token1_price +
                        depositLPRatio * token1Reserve * token2_price);

                // eslint-disable-next-line
                const apy = dpr * 365 * 100;

                isMounted.current && setApy(0);
            } catch (error) {
                console.log(error);
            }
        })();

        return () => (isMounted.current = false);

        // eslint-disable-next-line
    }, [chainId, reserves, totalSupply, lmBalance]);

    return (
        <div className='apy'>
            <p>APR</p>
            <span>{apy.toFixed(4) || '0.000'}%</span>
        </div>
    );
};

export default Apy;
