import React from 'react';

const Uploaded = () => {
    return (
        <svg
            fill='none'
            height='24'
            stroke='#000'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
            viewBox='0 0 24 24'
            width='24'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path d='M3 17v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3' />
            <polyline points='16 6 12 2 8 6' />
            <line x1='12' x2='12' y1='2' y2='16' />
        </svg>
    );
};

export default Uploaded;
