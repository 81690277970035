export const networks = {
    MainNet: 1,
    Ropsten: 3,
    Rinkeby: 4,
    Goerli: 5,
    Kovan: 42,
};

export const ETH = 'ETH';
export const USDC = 'USDC';

export const BLOCKS_PER_DAY = 6539;
