import { Contract } from '@ethersproject/contracts';
import { formatUnits } from '@ethersproject/units';
import { WETH } from '@uniswap/sdk';
import { useWeb3React } from '@web3-react/core';
import React, { Fragment, useContext, useEffect } from 'react';
import useSWR from 'swr';
import config from '../config/config';
import { BLOCKS_PER_DAY, ETH } from '../config/constants';
import walletContext from '../context/wallet/walletContext';
import { fetcher } from '../web3/utils';
import Claim from './Claim';
import Withdraw from './Withdraw';

const Rewards = () => {
    const { chainId, library, account } = useWeb3React();

    const { selectedSymbol, pair, setPair } = useContext(walletContext);

    const poolID = config.poolIds.pair1;

    const { data: stakedLP } = useSWR(
        [config.miningAddress, 'userInfo', poolID, account],
        {
            fetcher: fetcher(library, config.miningAbi),
        }
    );

    const { data: pairAddress } = useSWR(
        [
            config.uniswap.addresses.factory,
            'getPair',

            selectedSymbol === ETH
                ? WETH[chainId]?.address
                : config.tokensByNetwork[chainId]?.[0].address ?? '',
            config.tokensByNetwork[chainId]?.[1].address ?? '',
        ],
        {
            fetcher: fetcher(library, config.uniswap.abis.factory),
        }
    );

    const { data: totalStakedLP } = useSWR(
        [pairAddress, 'balanceOf', config.miningAddress],
        {
            fetcher: fetcher(library, config.erc20abi),
        }
    );

    useEffect(() => {
        if (!library || !account || !stakedLP || !totalStakedLP) {
            return;
        }

        // using contract because useSWR wasn't updating poolInfo properly
        const contract = new Contract(
            config.miningAddress,
            config.miningAbi,
            library.getSigner()
        );

        (async () => {
            const poolInfo = await contract.poolInfo(poolID);

            const staked = formatUnits(stakedLP.amount, 18);
            const totalStaked = formatUnits(totalStakedLP.toString(), 18);
            const tokensPerDay = formatUnits(poolInfo.tokenPerBlock, 18) * BLOCKS_PER_DAY;
            const tokensPerWeek = tokensPerDay * 7;

            // const dailyRewards = (staked / totalStakedLP) * tokensPerDay;
            const weeklyRewards = (staked / totalStaked) * tokensPerWeek;

            const shareOfPool = (staked / totalStaked) * 100;

            const currentPair = selectedSymbol === ETH ? 2 : 1;

            setPair({
                ...pair,
                [currentPair]: {
                    ...pair[currentPair],
                    weeklyRewards,
                    shareOfPool,
                },
            });
        })();

        // eslint-disable-next-line
    }, [library, account, stakedLP, totalStakedLP, poolID, selectedSymbol]);

    if (account && stakedLP && formatUnits(stakedLP.amount, 18) > 0) {
        return (
            <div className='rewards-container'>
                <div className='estimated-rewards-container'>
                    <div className='estimate-rewards'>
                        <p>
                            <span>Share of pool:</span>
                            <span className='amount'>
                                {Number(
                                    pair?.[selectedSymbol === ETH ? 2 : 1]?.shareOfPool
                                ).toFixed(8) ?? 0.0}
                                %
                            </span>
                        </p>
                    </div>
                    <div className='estimate-rewards'>
                        <p>
                            <span>Est. Weekly Rewards:</span>
                            <span className='amount'>
                                {Number(
                                    pair?.[selectedSymbol === ETH ? 2 : 1]?.weeklyRewards
                                ).toFixed(4) ?? 0.0}{' '}
                                {config.tokensByNetwork[chainId][1].symbol}
                            </span>
                        </p>
                    </div>
                </div>
                <div className='actions-container'>
                    <Claim />
                    <Withdraw />
                </div>
            </div>
        );
    }

    return <Fragment />;
};

export default Rewards;
