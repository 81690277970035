import {
    RESET_TOKEN_BALANCE,
    RESET_TOKEN_INPUTS,
    SET_INSUFFICIENT_BALANCE,
    SET_MODAL_CLOSE,
    SET_MODAL_MESSAGE,
    SET_MODAL_OPEN,
    SET_PAIR,
    SET_PRICE,
    SET_SELECTED_SYMBOL,
    SET_TOKEN_BALANCE,
    SET_TOKEN_INPUT,
} from '../types';

const reducer = (state, action) => {
    switch (action.type) {
        case SET_SELECTED_SYMBOL:
            return {
                ...state,
                selectedSymbol: action.payload,
            };
        case SET_PRICE:
            return {
                ...state,
                price: action.payload,
            };
        case SET_TOKEN_BALANCE:
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            };
        case SET_TOKEN_INPUT:
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            };
        case SET_PAIR:
            return {
                ...state,
                pair: action.payload,
            };
        case SET_INSUFFICIENT_BALANCE:
            return {
                ...state,
                insufficientBalance: action.payload,
            };
        case SET_MODAL_MESSAGE:
            return {
                ...state,
                modal: { ...state.modal, message: action.payload },
            };
        case SET_MODAL_OPEN:
            return {
                ...state,
                modal: { ...state.modal, [action.payload]: true },
            };
        case SET_MODAL_CLOSE:
            return {
                ...state,
                modal: { ...state.modal, [action.payload]: false },
            };
        case RESET_TOKEN_BALANCE:
            return {
                ...state,
                token1Balance: 0,
                token2Balance: 0,
            };
        case RESET_TOKEN_INPUTS:
            return {
                ...state,
                token1Input: '',
                token2Input: '',
            };
        default:
            return {
                ...state,
            };
    }
};

export default reducer;
