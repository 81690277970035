import React from 'react';
import config from '../../config/config';
import ExternalLink from '../reusable/ExternalLink';

const Footer = () => {
    return (
        <footer>
            <div className='container'>
                <div className='copyrights'>
                    &copy; 2020-2021 <span className='app-name'>{config.appName}</span>.{' '}
                    <span>All rights reserved.</span>
                    <div className='powered-by'>
                        Powered by{' '}
                        <ExternalLink to='https://www.faculty.group/'>
                            FacultyGroup
                        </ExternalLink>
                    </div>
                </div>
                <div className='social-media-container'>
                    {config.socialMedias && config.socialMedias.length
                        ? config.socialMedias.map(({ name, link, icon }) => (
                              <ExternalLink key={name} to={link}>
                                  <img src={icon} alt={`${name} icon`} />
                              </ExternalLink>
                          ))
                        : null}
                </div>
            </div>
        </footer>
    );
};

export default Footer;
