import React from 'react';

const CrossIcon = () => {
    return (
        <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect
                y='21.3441'
                width='30.1852'
                height='3.35391'
                rx='1.67696'
                transform='rotate(-45 0 21.3441)'
                fill='white'
            />
            <rect
                y='21.3441'
                width='30.1852'
                height='3.35391'
                rx='1.67696'
                transform='rotate(-45 0 21.3441)'
                fill='white'
            />
            <rect
                width='30.1852'
                height='3.35391'
                rx='1.67696'
                transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 23.7157 21.3441)'
                fill='white'
            />
            <rect
                width='30.1852'
                height='3.35391'
                rx='1.67696'
                transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 23.7157 21.3441)'
                fill='white'
            />
        </svg>
    );
};

export default CrossIcon;
