import React from 'react';
import Fluid from '../../assets/layout/Fluid';
import MeteorBig from '../../assets/layout/MeteorBig';
import MeteorSmall from '../../assets/layout/MeteorSmall';
import Planet from '../../assets/layout/Planet';
import RedMeteor from '../../assets/layout/RedMeteor';
import ShootingStar from '../../assets/layout/ShootingStar';
import SmallMeteorTop from '../../assets/layout/SmallMeteorTop';

const AppBackground = () => (
    <div className='app-background'>
        <Fluid />
        <SmallMeteorTop />
        <MeteorBig />
        <MeteorSmall />
        <Planet />
        <RedMeteor />
        <ShootingStar />
    </div>
);

export default AppBackground;
