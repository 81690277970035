const Logo = () => (
    <svg
        width='224'
        height='66'
        viewBox='0 0 224 66'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <g clipPath='url(#clip0)'>
            <path d='M79.9202 25.39V41.9678H84.9774V25.39H79.9202Z' fill='white' />
            <path
                d='M106.508 36.9886C105.841 36.3634 105.282 35.8413 104.844 35.4257C104.492 35.0984 104.188 34.8189 103.937 34.5873C103.869 34.5174 103.803 34.4585 103.745 34.3997C103.452 34.1313 103.212 33.918 103.031 33.7672C102.85 33.6238 102.69 33.4987 102.542 33.3958C102.69 33.2854 102.857 33.1494 103.042 32.9912C103.227 32.8294 103.459 32.6345 103.742 32.3918C104.017 32.1528 104.351 31.8586 104.742 31.4982C105.134 31.1452 105.609 30.7039 106.181 30.1707L111.344 25.39H104.797L99.5436 30.5494L94.3378 25.39H87.3266L92.333 30.1486C92.9674 30.7591 93.4967 31.2555 93.9173 31.6453C94.3414 32.0351 94.6931 32.3551 94.9686 32.6088C95.2441 32.8699 95.4761 33.0648 95.6537 33.2082C95.835 33.3443 95.9945 33.4767 96.1431 33.5907C95.9945 33.7083 95.8278 33.8371 95.6429 33.9878C95.4507 34.1313 95.2187 34.3335 94.9432 34.5799C94.6677 34.8263 94.3124 35.1462 93.8955 35.536C93.4714 35.9185 92.9457 36.4002 92.3294 36.9813L91.898 37.3858L87.0076 41.9715H93.2865L99.0325 36.4958L104.604 41.9715H111.735L106.508 36.9886Z'
                fill='white'
            />
            <path
                d='M142.622 33.1346C141.582 32.3844 140.056 32.0093 138.051 32.0093H129.162C128.571 32.0093 128.136 31.9247 127.853 31.7482C127.621 31.6121 127.487 31.3915 127.443 31.0899C127.429 31.0127 127.425 30.9281 127.425 30.8436C127.425 30.4133 127.567 30.1191 127.857 29.9499C128.139 29.7881 128.575 29.7036 129.165 29.7036H140.502L143.75 25.3936H129.55C128.317 25.3936 127.251 25.5186 126.367 25.765C125.475 26.0114 124.75 26.3571 124.192 26.8057C123.626 27.247 123.216 27.7876 122.945 28.4275C122.676 29.0674 122.538 29.7698 122.538 30.5494C122.538 30.9208 122.571 31.2775 122.64 31.6158C122.698 31.921 122.789 32.2079 122.905 32.4874C123.148 33.0721 123.521 33.5759 124.028 33.9914C124.536 34.4033 125.17 34.7269 125.935 34.9549C126.7 35.1903 127.61 35.3006 128.669 35.3006H137.565C138.167 35.3006 138.606 35.3925 138.888 35.5727C139.164 35.7566 139.298 36.0618 139.298 36.4921C139.298 36.9223 139.164 37.2349 138.888 37.4225C138.606 37.61 138.17 37.7093 137.565 37.7093H125.203L121.955 41.9678H137.174C138.413 41.9678 139.479 41.8427 140.364 41.5963C141.248 41.35 141.981 40.9969 142.553 40.5336C143.119 40.0702 143.536 39.4965 143.8 38.8199C144.058 38.1432 144.192 37.382 144.192 36.5399C144.192 35.0211 143.663 33.8885 142.622 33.1346Z'
                fill='white'
            />
            <path
                d='M170.362 25.39L169.123 30.0272L168.822 31.1672C168.579 32.0903 168.361 32.9177 168.173 33.6421C167.988 34.3703 167.825 35.069 167.698 35.7272C167.564 35.4477 167.43 35.1609 167.292 34.8594C167.151 34.5615 166.998 34.2342 166.817 33.8812C166.636 33.5244 166.433 33.1273 166.208 32.6897C165.976 32.2521 165.708 31.7446 165.4 31.1672L164.718 29.8507L163.221 26.9713C163.011 26.5594 162.789 26.2358 162.572 25.9931C162.354 25.7504 162.122 25.5702 161.89 25.4341C161.651 25.3054 161.401 25.2208 161.147 25.173C160.886 25.1362 160.6 25.1179 160.292 25.1179C160.002 25.1179 159.719 25.1362 159.465 25.1767C159.2 25.2208 158.958 25.3054 158.722 25.4378C158.483 25.5739 158.261 25.7577 158.048 25.9968C157.837 26.2395 157.623 26.5631 157.413 26.975L155.97 29.711L155.22 31.1452C154.647 32.219 154.187 33.1052 153.835 33.8076C153.487 34.51 153.179 35.1499 152.925 35.7272C152.791 35.0506 152.642 34.3519 152.468 33.6421C152.302 32.9324 152.084 32.105 151.819 31.1672L151.442 29.7404L150.286 25.39H145.229L146.501 29.8507L149.383 39.9636C149.608 40.7396 149.981 41.3169 150.493 41.6883C151.007 42.0597 151.577 42.2436 152.215 42.2436C152.882 42.2436 153.44 42.0818 153.889 41.7619C154.339 41.4419 154.712 41.0117 154.999 40.4858L157.616 35.7787C157.968 35.1352 158.283 34.5688 158.541 34.0908C158.805 33.609 159.034 33.1788 159.24 32.789C159.44 32.3992 159.617 32.0461 159.78 31.7335C159.933 31.421 160.074 31.1157 160.205 30.8142C160.353 31.1121 160.498 31.421 160.661 31.7335C160.814 32.0461 160.995 32.3992 161.209 32.789C161.419 33.1788 161.658 33.6164 161.923 34.1055C162.18 34.5946 162.488 35.1609 162.847 35.8045L165.465 40.4858C165.773 41.0337 166.15 41.464 166.599 41.7766C167.042 42.0892 167.593 42.2436 168.242 42.2436C168.876 42.2436 169.456 42.0597 169.967 41.6883C170.482 41.3169 170.852 40.7359 171.077 39.9636L173.89 30.2883L175.307 25.39H170.362Z'
                fill='white'
            />
            <path
                d='M184.432 37.713L181.376 41.9715H200.39L193.81 32.3072L190.178 26.975C189.819 26.4491 189.38 26.0041 188.869 25.6548C188.354 25.2944 187.72 25.1179 186.948 25.1179C186.15 25.1179 185.49 25.3054 184.972 25.6842C184.454 26.0666 184.022 26.4969 183.674 26.975L180.977 30.8363L173.19 41.9715H179.179L186.549 31.3952L186.85 30.965L187.191 31.4724L189.783 35.3521C190.058 35.7493 190.348 36.1722 190.649 36.6282C190.95 37.0842 191.215 37.463 191.447 37.7535C191.055 37.7204 190.591 37.7094 190.065 37.7094H184.432V37.713Z'
                fill='white'
            />
            <path
                d='M223.236 29.0821C222.877 28.3209 222.355 27.67 221.678 27.1221C220.992 26.5741 220.151 26.1512 219.162 25.846C218.168 25.5408 217.03 25.39 215.761 25.39H205.502L202.152 29.7H216.229C217.012 29.7 217.614 29.8691 218.045 30.2074C218.48 30.5458 218.694 31.0091 218.694 31.5828C218.694 32.1638 218.484 32.6235 218.045 32.9765C217.614 33.3222 217.012 33.4987 216.229 33.4987H202.152V41.9715H207.184V37.7314H215.739C216.947 37.7314 218.02 37.6027 218.973 37.3343C219.031 37.3196 219.082 37.3012 219.133 37.2902C220.126 36.9923 220.967 36.5804 221.663 36.0545C222.355 35.5213 222.877 34.8814 223.236 34.1349C223.595 33.3811 223.777 32.5389 223.777 31.6159C223.777 30.6855 223.595 29.8471 223.236 29.0821Z'
                fill='white'
            />
            <path
                d='M79.9202 41.0705C81.5624 40.4895 83.2518 39.9305 84.9774 39.39V41.9678H79.9202V41.0705Z'
                fill='white'
            />
            <path
                d='M106.508 36.9886L111.732 41.9715H104.601L99.0288 36.4921L93.2865 41.9715H87.0076L91.9053 37.3931C95.7552 36.3561 99.7756 35.4183 103.941 34.5872C104.191 34.8226 104.492 35.1021 104.847 35.4257C105.282 35.8412 105.841 36.3634 106.508 36.9886Z'
                fill='white'
            />
            <path
                d='M144.192 36.5473C144.192 37.3857 144.058 38.1507 143.8 38.8273C143.536 39.5039 143.119 40.0776 142.553 40.541C141.981 41.0043 141.252 41.3537 140.364 41.6038C139.479 41.8501 138.413 41.9752 137.174 41.9752H121.955L125.203 37.7167H137.565C138.167 37.7167 138.606 37.6174 138.888 37.4299C139.164 37.2423 139.298 36.9297 139.298 36.4995C139.298 36.0692 139.164 35.764 138.888 35.5801C138.606 35.3963 138.17 35.308 137.565 35.308H128.669C127.61 35.308 126.697 35.1977 125.935 34.9623C125.17 34.7343 124.536 34.407 124.028 33.9988C123.521 33.5833 123.148 33.0795 122.905 32.4948C122.789 32.2153 122.698 31.9284 122.64 31.6232C124.224 31.4357 125.823 31.2592 127.443 31.0974C127.487 31.3952 127.625 31.6195 127.853 31.7556C128.136 31.9321 128.571 32.0167 129.162 32.0167H138.051C140.052 32.0167 141.582 32.3955 142.622 33.142C143.663 33.8885 144.192 35.0212 144.192 36.5473Z'
                fill='white'
            />
            <path
                d='M173.879 30.2884L171.066 39.9637C170.841 40.7396 170.468 41.3169 169.956 41.6884C169.442 42.0598 168.865 42.2437 168.231 42.2437C167.582 42.2437 167.031 42.0892 166.589 41.7766C166.139 41.464 165.762 41.0338 165.454 40.4858L162.836 35.8045C162.478 35.1609 162.169 34.5946 161.912 34.1055C161.647 33.6164 161.412 33.1825 161.198 32.789C160.988 32.3992 160.806 32.0462 160.65 31.7336C160.491 31.421 160.342 31.1158 160.194 30.8142C160.067 31.1121 159.925 31.421 159.769 31.7336C159.61 32.0462 159.429 32.3992 159.229 32.789C159.023 33.1788 158.794 33.6091 158.53 34.0908C158.272 34.5652 157.957 35.1315 157.605 35.7787L154.988 40.4858C154.698 41.0117 154.328 41.442 153.878 41.7619C153.429 42.0818 152.871 42.2437 152.204 42.2437C151.569 42.2437 150.996 42.0598 150.482 41.6884C149.967 41.3169 149.597 40.7359 149.372 39.9637L146.49 29.8507C148.129 29.7993 149.778 29.7588 151.431 29.7404L151.808 31.1673C152.073 32.105 152.291 32.9324 152.457 33.6422C152.631 34.3519 152.78 35.0469 152.914 35.7273C153.172 35.1462 153.48 34.51 153.824 33.8076C154.176 33.1053 154.64 32.219 155.209 31.1452L155.959 29.711C158.907 29.7184 161.825 29.7625 164.714 29.8544L165.396 31.1709C165.704 31.752 165.972 32.2595 166.204 32.6934C166.429 33.131 166.636 33.5282 166.813 33.8849C166.995 34.2379 167.147 34.5615 167.288 34.8631C167.422 35.1609 167.556 35.4478 167.694 35.7309C167.821 35.0727 167.984 34.3776 168.169 33.6458C168.354 32.9214 168.575 32.0976 168.818 31.1709L169.119 30.0309C170.721 30.1008 172.306 30.1891 173.879 30.2884Z'
                fill='white'
            />
            <path
                d='M186.553 31.3952L179.183 41.9715H173.194L180.981 30.8362C182.859 31.0054 184.715 31.193 186.553 31.3952Z'
                fill='white'
            />
            <path
                d='M193.811 32.3072L200.39 41.9715H181.376L184.432 37.713H190.069C190.595 37.713 191.059 37.7278 191.45 37.7572C191.218 37.463 190.957 37.0879 190.653 36.6319C190.352 36.1759 190.062 35.753 189.787 35.3558L187.194 31.4761C189.435 31.7225 191.635 32.002 193.811 32.3072Z'
                fill='white'
            />
            <path
                d='M218.977 37.3343C218.02 37.6027 216.946 37.7314 215.743 37.7314H207.187V41.9715H202.156V33.6348C208.05 34.6755 213.673 35.9221 218.977 37.3343Z'
                fill='white'
            />
        </g>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M15.8229 18.4379C22.9564 16.8469 32.0338 16.9433 39.4194 18.8167C40.2377 19.0233 41.0319 19.2541 41.8018 19.5055C44.6435 20.4319 46.874 21.5683 48.5797 24.1305C48.5107 24.0031 48.445 23.8791 48.376 23.7517C48.2793 23.5726 48.1861 23.397 48.0894 23.2179C43.8286 16.5507 26.3781 15.0871 19.3654 15.4177C25.47 13.992 32.7658 13.8336 37.3339 15.218C36.2117 14.5946 32.645 12.9106 29.9 11.1371C27.3518 9.49095 25.9879 7.34548 29.4166 4.88661C35.5591 0.485459 25.2525 1.07779 21.3784 1.52548C26.133 0.382146 29.6134 0.247838 32.4965 0.647317C25.8222 -0.857616 19.0754 0.340821 14.1172 3.41956C9.85294 6.07128 6.58658 10.4965 6.06521 13.93C5.92019 14.8701 8.24739 13.9575 8.08165 14.8701C7.85377 16.1271 6.08938 19.8257 6.91805 19.3436C8.34752 18.5102 11.6553 17.4117 14.9907 17.4633C14.3692 17.6286 13.7753 17.8077 13.216 18.0005C9.6803 19.23 6.71779 20.7487 4.68408 23.8756C7.55337 20.27 11.462 19.4091 15.8229 18.4379Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M44.6919 22.8734C35.8837 16.5506 14.787 16.9777 7.19775 24.4713C14.2346 18.5343 36.4224 18.0728 44.6919 22.8734Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M11.0475 30.4602C10.9059 30.4326 10.7609 30.5428 10.7264 30.7081C10.6919 30.8734 10.7816 31.0318 10.9232 31.0594C11.0648 31.0869 11.2098 30.9767 11.2443 30.8114C11.2788 30.6461 11.1925 30.4877 11.0475 30.4602Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M13.8582 41.1117C13.8237 41.277 13.9135 41.4354 14.055 41.4629C14.1966 41.4905 14.3416 41.3803 14.3762 41.215C14.4107 41.0497 14.3209 40.8913 14.1793 40.8637C14.0343 40.8362 13.8928 40.9464 13.8582 41.1117Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M17.6083 42.4549L17.4149 42.0967L17.4909 42.4962L17.187 42.6994L17.5461 42.5961L17.7257 42.9405L17.66 42.5548L18.0502 42.3757L17.6083 42.4549Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M14.7217 45.2307C14.7045 45.3134 14.7493 45.3926 14.8184 45.4063C14.8909 45.4201 14.96 45.365 14.9772 45.2824C14.9945 45.1997 14.9496 45.1205 14.8806 45.1067C14.8115 45.0929 14.739 45.148 14.7217 45.2307Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M18.0326 47.8134C18.1741 47.8409 18.3192 47.7307 18.3537 47.5654C18.3882 47.4001 18.2984 47.2417 18.1569 47.2142C18.0153 47.1866 17.8703 47.2968 17.8358 47.4621C17.8012 47.624 17.891 47.7824 18.0326 47.8134Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M20.5772 47.7238C20.5599 47.8064 20.6048 47.8857 20.6739 47.8994C20.7464 47.9132 20.8154 47.8581 20.8327 47.7755C20.85 47.6928 20.8051 47.6136 20.736 47.5998C20.667 47.5826 20.5945 47.6411 20.5772 47.7238Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M23.3812 45.9673C23.3467 46.1327 23.4364 46.2911 23.578 46.3186C23.7196 46.3462 23.8646 46.236 23.8991 46.0707C23.9336 45.9054 23.8439 45.7469 23.7023 45.7194C23.5573 45.6884 23.4157 45.802 23.3812 45.9673Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M21.3267 42.3722C21.3992 42.3859 21.4683 42.3308 21.4855 42.2482C21.5028 42.1655 21.4579 42.0863 21.3889 42.0725C21.3163 42.0588 21.2473 42.1139 21.23 42.1965C21.2128 42.2792 21.2576 42.3549 21.3267 42.3722Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M25.9327 44.0974C25.9154 44.18 25.9603 44.2593 26.0293 44.273C26.1018 44.2868 26.1709 44.2317 26.1882 44.1491C26.2054 44.0664 26.1605 43.9872 26.0915 43.9734C26.019 43.9596 25.9499 44.0147 25.9327 44.0974Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M26.4302 49.4215C26.413 49.5041 26.4578 49.5834 26.5269 49.5971C26.5994 49.6109 26.6685 49.5558 26.6857 49.4732C26.703 49.3905 26.6581 49.3113 26.589 49.2975C26.52 49.2837 26.4475 49.3388 26.4302 49.4215Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M33.3356 50.6992C33.2631 50.6854 33.1941 50.7405 33.1768 50.8232C33.1595 50.9058 33.2044 50.985 33.2735 50.9988C33.346 51.0126 33.415 50.9575 33.4323 50.8748C33.4496 50.7922 33.4081 50.713 33.3356 50.6992Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M29.7897 46.9902C29.9312 47.0178 30.0762 46.9076 30.1108 46.7423C30.1453 46.577 30.0555 46.4185 29.914 46.391C29.7724 46.3634 29.6274 46.4736 29.5928 46.6389C29.5583 46.8008 29.6481 46.9592 29.7897 46.9902Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M14.3831 35.1026L13.7374 33.911L13.986 35.2403L12.9778 35.9118L14.169 35.5744L14.7663 36.7211L14.5557 35.44L15.8471 34.8374L14.3831 35.1026Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M40.5 41.7213C40.4828 41.804 40.5277 41.8832 40.5967 41.8969C40.6692 41.9107 40.7383 41.8556 40.7556 41.773C40.7728 41.6903 40.7279 41.6111 40.6589 41.5973C40.5864 41.5835 40.5139 41.6386 40.5 41.7213Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M46.1144 40.7502C46.0419 40.7364 45.9729 40.7915 45.9556 40.8742C45.9383 40.9568 45.9832 41.0361 46.0523 41.0498C46.1248 41.0636 46.1939 41.0085 46.2111 40.9258C46.2318 40.8432 46.1869 40.764 46.1144 40.7502Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M8.19217 52.7519C8.92417 53.7988 9.7632 54.8905 9.91167 55.0903C8.00572 53.6404 6.05488 51.1334 4.71519 49.1876C5.50588 50.8165 6.37944 52.318 7.60519 54.1398C6.30693 53.1755 4.69102 51.4054 4.14893 50.5961C4.86366 53.9814 13.2505 63.8823 17.8877 65.0049C16.7621 63.1591 17.8152 60.7622 20.1216 61.0652C22.7147 61.4062 22.5317 64.8465 20.4773 65.5387C22.038 65.7901 23.7471 65.9451 25.5011 66.0002C24.8485 65.89 24.2478 65.7557 23.716 65.5938C22.2002 65.1048 23.9197 65.5112 23.5261 63.624C22.7769 61.3304 23.9646 62.1604 26.9996 61.7058C28.1529 61.5336 29.5547 61.2305 30.8495 60.9275C18.9132 61.3132 15.6399 60.9723 8.19217 52.7519ZM17.3905 64.3196C16.2683 63.042 17.2903 60.9344 19.1307 60.986C17.5976 61.1961 16.8035 62.9146 17.3905 64.3196ZM20.9365 65.4939C22.6733 64.9395 22.7527 62.2465 21.4959 61.3752C23.1429 62.0915 23.0324 65.3631 20.9365 65.4939Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M21.6789 63.4102C21.6789 62.3323 20.8019 61.4576 19.7212 61.4576C18.6404 61.4576 17.7634 62.3323 17.7634 63.4102C17.7634 64.4881 18.6404 65.3628 19.7212 65.3628C20.8019 65.3628 21.6789 64.4881 21.6789 63.4102ZM18.5438 64.5845C17.8946 63.9371 17.8946 62.8833 18.5438 62.2359C19.1929 61.5884 20.2495 61.5884 20.8986 62.2359C21.5477 62.8833 21.5477 63.9371 20.8986 64.5845C20.2495 65.232 19.1929 65.232 18.5438 64.5845Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M27.9631 57.8279C23.1049 57.611 18.2089 56.2472 15.9369 54.6183C18.088 55.097 20.3738 55.3794 22.701 55.4483C15.8748 54.7767 11.2169 52.1147 8.15081 48.1096C8.62385 48.8879 9.16939 49.6765 9.79435 50.4823C8.16807 49.4148 5.69586 46.1879 5.04673 43.0748C4.73252 43.7119 4.98113 44.5108 4.91207 45.1686C4.87754 45.5164 4.64275 45.9538 4.63584 46.3085C4.60822 47.6378 6.23795 49.494 7.57419 50.8646C7.78826 51.0816 8.0127 51.3054 8.23713 51.5293C8.54098 51.8254 8.76196 52.0389 8.83792 52.1216C12.6982 55.9029 16.9141 60.2937 22.5732 59.5361C21.092 59.0677 18.8476 58.1964 17.829 57.3802C20.5533 57.8796 26.7028 57.9381 27.9631 57.8279Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M14.9634 51.9287C18.2816 53.4991 23.7957 54.3084 26.1609 54.1672C23.0603 53.8951 18.7788 53.103 15.2535 51.4776C12.4705 50.1965 10.1364 48.0407 8.75872 45.7747C10.3988 47.7411 12.5706 49.5869 15.0567 50.8646C18.5578 52.6691 22.6252 53.5301 26.2092 53.5714C44.2778 53.7711 53.1619 39.8445 48.58 24.127C52.4437 40.6159 42.9934 52.7173 26.23 52.4212C9.30772 52.1216 2.47114 39.4898 4.67749 23.8791C2.8406 31.1799 3.64855 40.0201 8.16138 46.2258C10.1088 48.8947 12.398 50.713 14.9634 51.9287Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M36.906 58.6957C38.3769 58.0827 40.2933 56.0268 40.2933 56.0268C40.2933 56.0268 38.736 57.4181 36.7196 58.341C35.6734 58.8197 34.4407 59.1021 33.3704 59.1503C33.3669 59.1503 35.5008 59.2812 36.906 58.6957Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M20.0042 62.0843C18.9926 61.9466 18.4954 62.7352 18.4954 62.7352C20.3115 61.4576 21.0643 63.362 21.0643 63.362C21.0643 63.362 21.0159 62.2255 20.0042 62.0843Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M34.3232 61.7194C32.6728 62.7973 33.3357 65.6728 35.3694 65.7727C33.6292 65.3491 33.0871 62.9144 34.3232 61.7194Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M34.7996 63.4895C34.7996 63.4895 35.5316 61.1201 37.6516 62.7835C36.7884 61.4163 34.8755 61.8364 34.7996 63.4895Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M37.3684 61.0823C38.4733 61.4542 39.0568 63.152 38.5458 64.1645C39.4194 63.1795 38.791 61.1753 37.3684 61.0823Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M39.012 64.34C41.826 64.0955 46.3837 56.8877 46.7946 56.0818C45.486 58.186 41.3184 63.1313 39.012 64.34Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M44.1739 49.6454C41.6809 52.2421 37.655 54.3393 33.8879 55.1417C37.6273 54.818 42.3094 52.7173 44.1739 49.6454Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M46.6118 51.8978L46.7154 51.7738C45.317 53.568 42.9863 56.447 40.8905 58.4513C40.1688 59.14 38.4252 60.5244 34.7134 60.8964C35.984 60.8137 38.5633 60.3075 39.1295 61.7917C39.3919 62.4736 39.3091 63.3759 39.3091 63.3759C39.6958 62.539 39.6129 62.0466 39.5093 61.5369C39.2573 60.2834 39.6198 60.3867 40.7006 59.2227C42.5375 57.2391 45.6243 53.6885 46.8086 51.6636L49.2048 48.7639C49.2048 48.7639 49.8643 50.3997 49.6364 51.2193C49.4086 52.0389 46.8535 55.9959 46.8535 55.9959C46.8535 55.9959 50.1509 51.8254 50.1647 50.5994C50.1785 49.3769 49.5777 47.4863 49.5777 47.4863L46.6118 51.8978Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M46.6667 50.0829C46.6667 50.0829 49.322 46.8802 49.3496 46.3774C49.3772 45.8746 49.0699 45.3477 48.7833 44.7381C48.7522 44.6314 48.7143 44.5143 48.6728 44.38C48.2309 42.9715 48.4519 42.3378 48.4519 42.3378C47.896 42.9474 48.3863 45.0102 48.773 45.6404C48.88 45.9263 48.9594 46.1811 48.8835 46.3946C48.6625 47.0283 46.6667 50.0829 46.6667 50.0829Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M51.4735 32.6538C51.7359 31.5655 51.3595 27.8772 50.4998 26.3344C50.8727 27.5535 51.2594 31.9581 50.8865 33.1979C53.445 32.9671 53.2931 31.2177 53.2965 29.4028C53.1791 31.6275 52.4817 32.2956 51.4735 32.6538Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M52.6921 26.2691C52.882 26.0797 52.9994 25.8214 52.9994 25.5321C52.9994 25.2463 52.882 24.9846 52.6921 24.7951C52.5022 24.6057 52.2432 24.4886 51.9532 24.4886C51.6666 24.4886 51.4042 24.6057 51.2143 24.7951C51.0244 24.9846 50.907 25.2428 50.907 25.5321C50.907 25.8179 51.0244 26.0797 51.2143 26.2691C51.4042 26.4585 51.6631 26.5756 51.9532 26.5756C52.2432 26.5721 52.5022 26.455 52.6921 26.2691ZM51.4318 25.5321C51.4318 25.3875 51.4905 25.2566 51.5837 25.1636C51.677 25.0706 51.8082 25.0121 51.9532 25.0121C52.0982 25.0121 52.2294 25.0706 52.3226 25.1636C52.4159 25.2566 52.4746 25.3875 52.4746 25.5321C52.4746 25.6768 52.4159 25.8076 52.3226 25.9006C52.2294 25.9936 52.0982 26.0521 51.9532 26.0521C51.8082 26.0521 51.677 25.9936 51.5837 25.9006C51.4905 25.8076 51.4318 25.6768 51.4318 25.5321Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M53.2409 22.0401C52.8439 20.728 51.7217 19.8085 50.2922 20.4043C51.2866 20.2838 52.3846 21.1172 52.7783 22.2123C53.4308 24.0272 53.4895 26.6513 53.5172 27.5777C53.6898 26.7064 53.8107 23.9238 53.2409 22.0401Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M41.367 24.5265L40.7247 23.335L40.9699 24.6677L39.9617 25.3358L41.1563 24.9983L41.7537 26.1451L41.5396 24.864L41.9781 24.6608L42.8344 24.2613L41.9332 24.4232L41.367 24.5265Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M39.2745 29.272C39.0742 29.4511 39.0811 29.7886 39.2918 30.0227C39.5024 30.2569 39.8373 30.3017 40.0376 30.1226C40.2378 29.9435 40.2309 29.606 40.0203 29.3719C39.8097 29.1342 39.4748 29.0895 39.2745 29.272Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M35.1865 25.5871C35.221 25.4218 35.1312 25.2633 34.9896 25.2358C34.8481 25.2082 34.7031 25.3184 34.6685 25.4837C34.634 25.649 34.7238 25.8075 34.8653 25.8384C35.0104 25.866 35.1519 25.7558 35.1865 25.5871Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M34.8722 37.2512C35.0034 37.0377 34.9585 36.7725 34.7755 36.6589C34.5925 36.5452 34.337 36.6313 34.2058 36.8449C34.0746 37.0584 34.1195 37.3235 34.3025 37.4372C34.4855 37.5474 34.741 37.4647 34.8722 37.2512Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M44.0083 25.3704L44.1878 25.7148L44.1222 25.3291L44.5124 25.1465L44.0704 25.2257L43.8771 24.871L43.953 25.2705L43.6492 25.4702L44.0083 25.3704Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M42.6789 29.7471C42.6962 29.6645 42.6513 29.5853 42.5822 29.5715C42.5097 29.5577 42.4406 29.6128 42.4234 29.6955C42.4061 29.7781 42.451 29.8573 42.5201 29.8711C42.5891 29.8849 42.6616 29.8298 42.6789 29.7471Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M40.8664 33.1221C40.8837 33.0395 40.8388 32.9603 40.7697 32.9465C40.6972 32.9327 40.6281 32.9878 40.6109 33.0705C40.5936 33.1531 40.6385 33.2323 40.7076 33.2461C40.7801 33.2599 40.8526 33.2048 40.8664 33.1221Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M40.0652 37.2686C39.934 37.4821 39.9788 37.7472 40.1618 37.8609C40.3448 37.9745 40.6003 37.8884 40.7316 37.6749C40.8628 37.4614 40.8179 37.1962 40.6349 37.0826C40.4519 36.9724 40.1964 37.055 40.0652 37.2686Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M43.3625 39.0145C43.29 39.0007 43.2209 39.0558 43.2037 39.1385C43.1864 39.2211 43.2313 39.3003 43.3003 39.3141C43.3728 39.3279 43.4419 39.2728 43.4592 39.1901C43.4799 39.1075 43.435 39.0283 43.3625 39.0145Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12.6947 38.1295L12.5013 37.7714L12.5773 38.1708L12.2734 38.374L12.6325 38.2707L12.8121 38.6151L12.7465 38.2294L13.1366 38.0503L12.6947 38.1295Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M11.2722 34.5067C11.3101 34.5135 11.4137 34.4653 11.431 34.3827C11.4482 34.3 11.3965 34.2208 11.3343 34.2071C11.2687 34.1933 11.2169 34.2312 11.1893 34.2897C11.1824 34.3035 11.1858 34.2863 11.1755 34.331C11.1651 34.3758 11.1927 34.4757 11.2514 34.4998C11.2583 34.5032 11.2342 34.4963 11.2722 34.5067Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M8.72746 27.0335L9.0831 27.7222L8.95535 26.9543L9.73223 26.5927L8.85176 26.7511L8.4685 26.0348L8.61697 26.8337L8.00928 27.2367L8.72746 27.0335Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M14.9147 25.7077C14.932 25.6251 14.8871 25.5459 14.8181 25.5321C14.7455 25.5183 14.6765 25.5734 14.6592 25.6561C14.642 25.7387 14.6868 25.8179 14.7559 25.8317C14.8284 25.8455 14.8975 25.7904 14.9147 25.7077Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M39.8889 45.1753L39.2467 43.9838L39.4953 45.3165L38.4871 45.9846L39.6783 45.6506L40.2756 46.7939L40.0615 45.5128L41.3563 44.9101L39.8889 45.1753Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M18.5437 21.4546C18.4125 21.6681 18.4574 21.9333 18.6404 22.0469C18.8234 22.1606 19.0789 22.0745 19.2101 21.861C19.3413 21.6475 19.2964 21.3823 19.1134 21.2686C18.9304 21.1584 18.6749 21.2411 18.5437 21.4546Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M21.434 30.2742C21.4443 30.2191 21.4063 30.1123 21.3373 30.0986C21.2648 30.0848 21.1923 30.1571 21.1784 30.2225C21.1646 30.288 21.1819 30.3224 21.2095 30.3569C21.2751 30.3534 21.3407 30.35 21.4063 30.3431V30.3465C21.4167 30.3259 21.4236 30.3293 21.434 30.2742Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M27.8766 22.2054C27.8939 22.1227 27.849 22.0435 27.78 22.0298C27.7075 22.016 27.6384 22.0711 27.6211 22.1537C27.6039 22.2364 27.6488 22.3156 27.7178 22.3294C27.7869 22.3431 27.8594 22.288 27.8766 22.2054Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M26.9894 30.2019C26.9169 30.1882 26.8479 30.2433 26.8306 30.3259C26.8133 30.4086 26.8582 30.4878 26.9273 30.5016C26.9963 30.5153 27.0689 30.4602 27.0861 30.3776C27.1068 30.2949 27.0619 30.2157 26.9894 30.2019Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M5.86155 25.1119C6.04455 25.2256 6.30005 25.1395 6.43126 24.926C6.56247 24.7124 6.51758 24.4473 6.33458 24.3336C6.15158 24.22 5.89607 24.3061 5.76487 24.5196C5.63366 24.7365 5.67855 25.0017 5.86155 25.1119Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M3.31683 19.8913C1.04833 19.7604 0.765204 20.8969 0.368131 23.0389C0.00213225 24.9984 -0.49162 31.3729 1.12775 32.7779C1.75271 33.322 2.42256 33.3289 3.16836 33.3151C2.35695 32.5472 2.12907 31.1869 1.81486 30.1296C1.85284 30.6324 1.89082 31.1387 1.9288 31.6415C1.29003 30.6324 0.924034 27.8051 1.78033 26.8271C2.23265 26.3139 2.68842 26.2588 2.69878 25.4048C2.71259 24.623 1.97024 23.8654 1.33492 24.1065C1.13466 24.1822 1.06905 23.6794 1.16228 23.0113C1.53173 20.3837 2.39493 20.2322 3.31683 19.8913ZM1.51792 24.3475C1.8045 24.3475 2.06692 24.4646 2.25682 24.654C2.44672 24.8434 2.56412 25.1017 2.56412 25.391C2.56412 25.6768 2.44672 25.9386 2.25682 26.128C2.06692 26.3174 1.80795 26.4345 1.51792 26.4345C1.22788 26.4345 0.96892 26.3174 0.779015 26.128C0.589111 25.9386 0.471715 25.6803 0.471715 25.391C0.471715 25.1052 0.589111 24.8434 0.779015 24.654C0.96892 24.4646 1.22788 24.3475 1.51792 24.3475Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M1.51796 25.9075C1.66298 25.9075 1.79418 25.849 1.88741 25.756C1.98063 25.663 2.03933 25.5321 2.03933 25.3875C2.03933 25.2429 1.98063 25.112 1.88741 25.019C1.79418 24.926 1.66298 24.8675 1.51796 24.8675C1.37294 24.8675 1.24173 24.926 1.14851 25.019C1.05528 25.112 0.996582 25.2429 0.996582 25.3875C0.996582 25.5321 1.05528 25.663 1.14851 25.756C1.24173 25.849 1.37294 25.9075 1.51796 25.9075Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M4.06253 23.2488C4.38019 20.4559 5.7475 16.7882 6.57618 15.4658C4.43543 16.8055 3.89334 21.644 4.06253 23.2488Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M47.8099 16.0619C47.1401 10.2729 41.2496 3.50582 34.7375 1.91479C40.2137 3.76411 45.7486 10.4623 47.8099 16.0619Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M36.2397 61.4576C35.159 61.4576 34.282 62.3323 34.282 63.4102C34.282 64.4881 35.159 65.3628 36.2397 65.3628C37.3205 65.3628 38.1975 64.4881 38.1975 63.4102C38.1975 62.3323 37.3205 61.4576 36.2397 61.4576ZM37.4171 64.5845C36.768 65.232 35.7114 65.232 35.0623 64.5845C34.4132 63.9371 34.4132 62.8833 35.0623 62.2359C35.7114 61.5884 36.768 61.5884 37.4171 62.2359C38.0663 62.8833 38.0663 63.9371 37.4171 64.5845Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M31.9201 2.63074C31.8337 3.6191 31.1259 4.40429 30.4181 5.02761C27.3209 7.76197 28.1807 9.22214 31.5782 11.2299C32.6693 11.8739 34.1471 12.721 35.3625 13.4029C34.9309 12.9724 33.0077 11.6569 31.8821 10.8063C29.1889 8.76411 28.5294 8.10291 30.8911 5.5063C31.5403 4.79343 32.438 3.63288 31.9201 2.63074Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M41.719 7.86207C41.7121 7.85518 41.7051 7.84485 41.6982 7.83796C41.6913 7.83107 41.6844 7.82419 41.6775 7.81386C40.8799 6.94602 39.6576 5.73725 38.6218 5.20002C38.5873 5.18281 38.3248 5.03817 38.2903 5.06227C38.2869 5.09671 38.532 5.27234 38.5631 5.29301C39.6197 6.12985 40.4967 7.06655 41.3806 8.07558C41.3875 8.08247 41.3944 8.08936 41.4013 8.09625C41.4082 8.10313 41.4151 8.11346 41.422 8.12035C41.4393 8.14101 41.46 8.16168 41.4807 8.18234C42.3888 9.18104 43.1795 10.1384 43.9529 11.2473C43.9702 11.2749 44.1256 11.509 44.1636 11.5056C44.1877 11.478 44.0669 11.2301 44.0496 11.1957C43.6042 10.1453 42.5304 8.79878 41.7811 7.92061C41.7569 7.90684 41.7362 7.88617 41.719 7.86207Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M15.488 48.2988C15.4638 48.3229 15.4397 48.3504 15.4328 48.3883C15.4155 48.471 15.4604 48.5502 15.5294 48.564C15.6019 48.5777 15.671 48.5226 15.6883 48.44C15.6917 48.4228 15.6814 48.4124 15.6814 48.3952C15.961 48.5398 16.2338 48.6914 16.5273 48.8222C24.7312 52.514 33.9502 50.0069 36.2152 48.7293C30.9773 50.0035 25.9535 50.0827 21.8205 49.3181L21.7825 49.2458L21.7963 49.3113C20.025 48.9806 18.416 48.4985 17.028 47.8821C9.29365 44.4521 5.87882 35.7806 5.75797 27.705C5.20207 36.1353 7.92634 44.3109 15.488 48.2988Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M11.897 41.4425C10.7092 40.4231 9.46624 37.7301 8.62375 34.8545C7.83651 32.1649 7.41872 29.31 7.32204 27.7121C6.89389 31.0663 8.49945 39.2798 11.897 41.4425Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M46.712 25.5871C47.9308 29.4476 47.4578 36.6898 45.7141 40.3368C48.3624 36.8965 48.8493 29.358 46.712 25.5871Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M18.0846 31.8273C18.2158 31.6138 18.1709 31.3486 17.9879 31.235C17.8049 31.1214 17.5494 31.2074 17.4182 31.421C17.287 31.6345 17.3319 31.8996 17.5149 32.0133C17.6979 32.1269 17.9534 32.0443 18.0846 31.8273Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M13.0572 25.1052C13.1884 24.8916 13.1436 24.6265 12.9606 24.5128C12.7776 24.3992 12.5221 24.4853 12.3908 24.6988C12.2596 24.9123 12.3045 25.1775 12.4875 25.2911C12.6705 25.4048 12.926 25.3187 13.0572 25.1052Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M44.0461 32.6229C43.9149 32.8364 43.9598 33.1016 44.1428 33.2152C44.3258 33.3288 44.5813 33.2428 44.7125 33.0292C44.8437 32.8157 44.7988 32.5506 44.6158 32.4369C44.4328 32.3267 44.1773 32.4094 44.0461 32.6229Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M31.1466 21.2895C31.112 21.4548 31.2018 21.6132 31.3434 21.6407C31.4849 21.6683 31.63 21.5581 31.6645 21.3928C31.699 21.2275 31.6092 21.0691 31.4677 21.0415C31.3227 21.0105 31.1776 21.1207 31.1466 21.2895Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M15.6881 32.4059C15.5466 32.3783 15.4015 32.4885 15.367 32.6538C15.3325 32.8191 15.4223 32.9776 15.5638 33.0051C15.7054 33.0327 15.8504 32.9225 15.8849 32.7572C15.9195 32.5919 15.8297 32.4334 15.6881 32.4059Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M29.0023 41.3322C28.9677 41.4975 29.0575 41.6559 29.1991 41.6835C29.3406 41.711 29.4857 41.6008 29.5202 41.4355C29.5547 41.2702 29.4649 41.1118 29.3234 41.0842C29.1784 41.0567 29.0333 41.1669 29.0023 41.3322Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M38.8568 33.8177L37.9591 33.9795L37.3928 34.0828L36.7506 32.8913L36.9958 34.2206L35.9875 34.8921L37.1788 34.5546L37.7796 35.7014L37.5655 34.4169L38.0005 34.2137L38.8568 33.8177Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M14.0241 30.7184L14.3832 31.4072L14.2554 30.6392L15.0323 30.2776L14.1518 30.436L13.7651 29.7197L13.9136 30.5187L13.3093 30.9216L14.0241 30.7184Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M41.9057 34.9233L41.519 34.207L41.6675 35.0059L41.0632 35.4088L41.778 35.2057L42.1371 35.8944L42.0093 35.123L42.7862 34.7649L41.9057 34.9233Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M34.064 42.1312C34.0295 42.2965 34.1193 42.4549 34.2609 42.4825C34.4024 42.51 34.5474 42.3998 34.582 42.2345C34.6165 42.0692 34.5267 41.9108 34.3852 41.8832C34.2401 41.8557 34.0951 41.9659 34.064 42.1312Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M25.0143 42.3584C25.1558 42.386 25.3009 42.2758 25.3354 42.1105C25.3699 41.9452 25.2801 41.7868 25.1386 41.7592C24.997 41.7317 24.852 41.8419 24.8175 42.0072C24.7829 42.1725 24.8727 42.3309 25.0143 42.3584Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M27.4591 34.2483L27.2658 33.8902L27.3383 34.2897L27.0344 34.4894L27.3935 34.3895L27.5731 34.7339L27.5109 34.3482L27.8976 34.1657L27.4591 34.2483Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M16.5998 43.4295C16.4582 43.402 16.3132 43.5122 16.2786 43.6775C16.2441 43.8428 16.3339 44.0012 16.4755 44.0322C16.617 44.0597 16.762 43.9495 16.7966 43.7842C16.8311 43.6155 16.7448 43.4571 16.5998 43.4295Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M30.6806 35.7189C30.539 35.6913 30.394 35.8015 30.3595 35.9668C30.3249 36.1321 30.4147 36.2905 30.5563 36.3181C30.6978 36.3457 30.8428 36.2354 30.8774 36.0701C30.9119 35.9048 30.8221 35.7464 30.6806 35.7189Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M30.3697 37.365L30.1798 37.0103L30.2523 37.4097L29.9485 37.6095L30.3076 37.5096L30.4871 37.854L30.4215 37.4683L30.8117 37.2858L30.3697 37.365Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M36.9401 29.7611C36.9574 29.6785 36.9125 29.5993 36.8434 29.5855C36.7709 29.5717 36.7019 29.6268 36.6846 29.7095C36.6673 29.7921 36.7122 29.8713 36.7813 29.8851C36.8538 29.8989 36.9229 29.8438 36.9401 29.7611Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M31.7093 30.2397C31.692 30.3223 31.7369 30.4015 31.806 30.4153C31.8785 30.4291 31.9475 30.374 31.9648 30.2913C31.982 30.2087 31.9372 30.1295 31.8681 30.1157C31.799 30.1019 31.7265 30.157 31.7093 30.2397Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M30.3419 29.0482C30.4731 28.8347 30.4282 28.5695 30.2452 28.4559C30.0622 28.3422 29.8067 28.4283 29.6755 28.6418C29.5443 28.8554 29.5892 29.1205 29.7722 29.2342C29.9552 29.3444 30.2107 29.2617 30.3419 29.0482Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M44.2291 35.6981L44.0392 35.34L44.1117 35.7394L43.8079 35.9392L44.167 35.8393L44.3465 36.1837L44.2809 35.798L44.6711 35.6189L44.2291 35.6981Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M24.4791 37.0896L24.2857 36.7314L24.3583 37.1309L24.0579 37.3307L24.4135 37.2308L24.593 37.5752L24.5309 37.1895L24.9176 37.0104L24.4791 37.0896Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M17.2559 36.862L17.1937 36.4763L17.5805 36.2973L17.1419 36.3765L16.9486 36.0183L17.0211 36.4178L16.7207 36.6175L17.0763 36.5177L17.2559 36.862Z'
            fill='white'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M19.8663 33.6421L19.673 33.2839L19.7455 33.6834L19.4451 33.8866L19.8007 33.7833L19.9803 34.1277L19.9181 33.742L20.3048 33.5629L19.8663 33.6421Z'
            fill='white'
        />
        <path
            d='M13.941 43.7567C13.0674 43.0129 12.2491 42.1554 11.4964 41.1773C11.2754 36.8106 11.0579 32.4439 10.8369 28.0806C11.6207 27.0647 12.4735 26.1693 13.3816 25.3945C13.5681 31.5141 13.7545 37.6371 13.941 43.7567Z'
            fill='white'
        />
        <path
            d='M30.3417 22.1196C29.1609 24.1928 27.9869 26.5345 26.8268 28.9865C26.4435 29.8027 26.1189 30.4915 25.8565 31.0493C25.5941 31.6072 25.3731 32.0756 25.1867 32.4544C25.0037 32.8332 24.8483 33.1466 24.724 33.3946C24.5997 33.6425 24.4892 33.8595 24.3925 34.042C24.4892 34.2004 24.5997 34.3967 24.7205 34.6309C24.8414 34.8651 25.0002 35.1991 25.197 35.633C25.3938 36.0635 25.639 36.6076 25.9359 37.255C26.2329 37.9025 26.6023 38.7187 27.0477 39.6864C28.2113 42.2416 29.3611 44.6798 30.4867 46.8322C28.933 47.2627 27.3619 47.5244 25.8013 47.6002C24.5755 44.7763 23.3394 41.794 22.1137 38.8908C20.8983 41.6838 19.7278 44.3182 18.616 46.5257C17.3557 46.047 16.1472 45.4065 15.0112 44.5972C15.9228 43.2748 16.8965 41.4082 17.9254 39.2214C18.2845 38.4604 18.5918 37.7888 18.8473 37.2378C19.0994 36.6868 19.3134 36.2254 19.4792 35.8603C19.6484 35.4953 19.7899 35.1991 19.9039 34.9753C20.0178 34.7514 20.1214 34.5517 20.2146 34.376C20.1179 34.2039 20.0144 34.0076 19.897 33.8009C19.7796 33.5909 19.6311 33.2981 19.455 32.9228C19.2755 32.5508 19.051 32.0825 18.7782 31.5384C18.5055 30.9942 18.1671 30.3055 17.7631 29.51C16.7031 27.4472 15.6707 25.6805 14.6729 24.3925C15.9987 23.4696 17.4213 22.767 18.906 22.2746C20.042 24.4442 21.2021 26.9512 22.3657 29.5857C23.4982 26.8376 24.6653 24.0447 25.8531 21.3964C27.355 21.4825 28.857 21.727 30.3417 22.1196Z'
            fill='white'
        />
        <path
            d='M41.0731 33.3151C42.1607 33.539 42.9031 33.9901 43.3761 34.4068C43.8457 34.8407 44.0322 35.2264 43.9735 35.8084C43.9424 36.1356 43.8526 36.4593 43.7076 36.8312C43.5626 37.2032 43.3485 37.6233 43.0619 38.1123C42.7753 38.5979 42.4128 39.1558 41.957 39.7516C41.5047 40.3439 40.9488 40.9741 40.2824 41.5527C37.5374 43.9116 34.3505 45.6714 31.0496 46.6736C31.7643 44.3766 32.4859 42.1553 33.2007 40.0684C35.8559 39.5587 38.3902 38.8252 40.6242 37.9022C40.9522 37.7679 41.187 37.5889 41.339 37.3891C41.4909 37.1894 41.5737 36.9414 41.5876 36.6384C41.6014 36.3353 41.5392 36.1356 41.4011 36.0323C41.2595 35.929 41.0282 35.8945 40.7002 35.9462C39.0843 36.1976 37.313 36.4111 35.4484 36.5833C34.7821 36.6453 34.202 36.5454 33.7117 36.263C33.2214 35.9806 32.8105 35.5467 32.4859 34.9647C32.1579 34.3827 31.9162 33.6595 31.7608 32.8054C31.6055 31.9514 31.5295 31.0009 31.5364 29.9678C31.5433 28.8417 31.64 27.8257 31.8264 26.951C32.0129 26.0729 32.296 25.3669 32.6758 24.8572C33.0556 24.3475 33.5425 24.041 34.1295 23.9584C34.7165 23.8757 35.4139 24.0548 36.2012 24.4922C39.2155 26.1589 41.9777 28.4422 44.2117 31.211C43.6454 31.18 43.055 31.4968 42.4473 32.127C40.5034 31.1318 38.2625 30.3018 35.8697 29.6613C35.4968 29.5614 35.2171 29.5958 35.0307 29.768C34.8442 29.9368 34.7475 30.3053 34.7406 30.8632C34.7337 31.421 34.82 31.8274 34.9996 32.0719C35.1791 32.3164 35.4554 32.4576 35.8248 32.5024C37.6859 32.7194 39.4606 32.9949 41.0731 33.3151Z'
            fill='white'
        />
        <defs>
            <clipPath id='clip0'>
                <rect
                    width='143.856'
                    height='17.1258'
                    fill='white'
                    transform='translate(79.9202 25.1179)'
                />
            </clipPath>
        </defs>
    </svg>
);

export default Logo;
