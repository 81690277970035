export const SET_SELECTED_SYMBOL = 'SET_SYMBOL';
export const SET_PRICE = 'SET_PRICE';
export const SET_PAIR = 'SET_PAIR';
export const SET_TOKEN_BALANCE = 'SET_TOKEN_BALANCE';
export const SET_TOKEN_INPUT = 'SET_TOKEN_INPUT';
export const SET_INSUFFICIENT_BALANCE = 'SET_INSUFFICIENT_BALANCE';
export const SET_MODAL_OPEN = 'SET_MODAL_OPEN';
export const SET_MODAL_CLOSE = 'SET_MODAL_CLOSE';
export const SET_MODAL_MESSAGE = 'SET_MODAL_MESSAGE';
export const RESET_TOKEN_BALANCE = 'RESET_TOKEN_BALANCE';
export const RESET_TOKEN_INPUTS = 'RESET_TOKEN_INPUTS';
